define("efs-components/templates/components/efs-breadcrumbs", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "f7CzBz4p",
    "block": "[[[10,\"li\"],[14,0,\"breadcrumbs__home\"],[15,\"data-test-breadcrumbs-home\",[30,0,[\"dataTestId\"]]],[12],[1,\"\\n  \"],[8,[39,0],[[24,\"data-test-breadcrumbs-home-link\",\"default-home\"]],[[\"@route\"],[\"index\"]],[[\"default\"],[[[[1,\"\\n    \"],[8,[39,1],null,[[\"@name\",\"@class\",\"@id\"],[\"home\",\"breadcrumbs__home-icon\",[28,[37,2],[[30,0,[\"dataTestId\"]],\"-home-icon\"],null]]],null],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"],[13],[1,\"\\n\"],[42,[28,[37,4],[[28,[37,4],[[30,0,[\"breadcrumbs\"]]],null]],null],null,[[[1,\"  \"],[10,\"li\"],[14,0,\"breadcrumbs__item\"],[15,\"data-test-breadcrumbs-item\",[30,2]],[12],[1,\"\\n    \"],[10,1],[14,0,\"breadcrumbs__separator\"],[15,\"data-test-breadcrumbs-separator\",[30,2]],[12],[1,\"\\n      /\\n    \"],[13],[1,\"\\n\"],[41,[30,1,[\"hasLink\"]],[[[1,\"      \"],[8,[39,0],[[16,0,[30,1,[\"className\"]]],[16,\"data-test-breadcrumb-link-a\",[30,2]]],[[\"@route\"],[[30,1,[\"route\"]]]],[[\"default\"],[[[[1,\"\\n        \"],[1,[30,1,[\"label\"]]],[1,\"\\n      \"]],[]]]]],[1,\"\\n\"]],[]],[[[1,\"      \"],[10,1],[15,0,[29,[\"breadcrumbs__text \",[52,[30,1,[\"current\"]],\"breadcrumbs__text--current\"]]]],[15,\"data-test-breadcrumbs-label\",[30,2]],[12],[1,\"\\n        \"],[1,[30,1,[\"label\"]]],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]]],[1,\"  \"],[13],[1,\"\\n\"]],[1,2]],null]],[\"crumb\",\"i\"],false,[\"link-to\",\"efs-icon\",\"concat\",\"each\",\"-track-array\",\"if\"]]",
    "moduleName": "efs-components/templates/components/efs-breadcrumbs.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});