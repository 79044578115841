define("efs-components/components/efs-modal-footer", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component"], function (_exports, _component, _templateFactory, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div
    class="modal__footer"
    data-test-modal-footer={{this.dataTestId}}
    ...attributes
  >
    {{yield}}
  </div>
  */
  {
    "id": "boECCO5X",
    "block": "[[[11,0],[24,0,\"modal__footer\"],[16,\"data-test-modal-footer\",[30,0,[\"dataTestId\"]]],[17,1],[12],[1,\"\\n  \"],[18,2,null],[1,\"\\n\"],[13]],[\"&attrs\",\"&default\"],false,[\"yield\"]]",
    "moduleName": "efs-components/components/efs-modal-footer.hbs",
    "isStrictMode": false
  });

  class EfsModalFooterComponent extends _component2.default {
    get dataTestId() {
      return this.args.dataTestId || "default-modal-footer";
    }

  }

  _exports.default = EfsModalFooterComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, EfsModalFooterComponent);
});