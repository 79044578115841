define("efs-components/components/efs-dropdown", ["exports", "@ember-decorators/component", "@ember/object", "@ember/component", "@glimmer/tracking", "efs-components/utils/gen-uuid", "efs-components/templates/components/efs-dropdown"], function (_exports, _component, _object, _component2, _tracking, _genUuid, _efsDropdown) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _class, _class2, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let EfsDropdown = (_dec = (0, _component.layout)(_efsDropdown.default), _dec2 = (0, _component.classNames)("dropdown"), _dec3 = (0, _component.classNameBindings)("active:dropdown--active"), _dec4 = (0, _component.attributeBindings)("dataTestId:data-test-dropdown", "data-tooltip", "data-tooltip-active", "data-tooltip-system", "name"), _dec5 = (0, _object.computed)("primary", "iconOnly", "iconRight"), _dec6 = (0, _object.computed)("active", "right"), _dec7 = (0, _object.computed)("active"), _dec(_class = _dec2(_class = _dec3(_class = _dec4(_class = (_class2 = class EfsDropdown extends _component2.default {
    constructor() {
      super(...arguments);

      // place simple properties and attributes in alphabetical order here:
      _initializerDefineProperty(this, "active", _descriptor, this);

      _defineProperty(this, "dataTestId", null);

      _defineProperty(this, "disabled", false);

      _defineProperty(this, "icon", "");

      _defineProperty(this, "iconOnly", false);

      _defineProperty(this, "iconRight", false);

      _defineProperty(this, "items", null);

      _defineProperty(this, "name", "");

      _defineProperty(this, "primary", false);

      _defineProperty(this, "right", false);

      _defineProperty(this, "value", "");
    }

    onBlur() {}

    onItemClick() {
      this.active = false;
    } // place complex computed properties here:


    get buttonClass() {
      let classes = ["button", "dd-trigger"];

      if (this.primary) {
        classes.push("button--primary");
      }

      if (this.iconOnly) {
        classes.push("button--icon-only");
      }

      if (this.iconRight) {
        classes.push("button--icon-right");
      }

      return classes.join(" ");
    }

    get listAlignClass() {
      if (this.right) {
        return "dropdown__list--right";
      }

      return "";
    }

    get listClassName() {
      if (this.active) {
        return "dropdown__list dropdown__list--active";
      }

      return "dropdown__list";
    }

    get listId() {
      return `dropdown-${(0, _genUuid.default)(this)}`;
    } // place methods and actions here:


    init() {
      super.init(...arguments);

      if (!this.dataTestId) {
        if (this.name) {
          this.set("dataTestId", this.name);
        } else {
          this.set("dataTestId", this.listId);
        }
      }

      this.active = this.active || false;
      this.iconOnly = this.iconOnly || false;
      this.iconRight = this.iconRight || false;
    }

    deactivateIfLostFocus(event) {
      // this only works on non-touch events
      if (!event.relatedTarget || !event.relatedTarget.offsetParent || !event.relatedTarget.offsetParent.offsetParent || event.relatedTarget.offsetParent.offsetParent.id && event.relatedTarget.offsetParent.offsetParent.id !== this.elementId) {
        this.active = false;
      }
    }

    blurEventAction(event) {
      this.deactivateIfLostFocus(event);
    }

    toggle() {
      this.toggleProperty("active");
    }

    itemClickAction() {
      return this.onItemClick();
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "active", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _applyDecoratedDescriptor(_class2.prototype, "buttonClass", [_dec5], Object.getOwnPropertyDescriptor(_class2.prototype, "buttonClass"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "listAlignClass", [_dec6], Object.getOwnPropertyDescriptor(_class2.prototype, "listAlignClass"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "listClassName", [_dec7], Object.getOwnPropertyDescriptor(_class2.prototype, "listClassName"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "blurEventAction", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "blurEventAction"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "toggle", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "toggle"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "itemClickAction", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "itemClickAction"), _class2.prototype)), _class2)) || _class) || _class) || _class) || _class);
  _exports.default = EfsDropdown;
});