define("efs-components/templates/components/efs-search-panel", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "ShM869o4",
    "block": "[[[10,0],[14,0,\"search-panel__header\"],[15,\"data-test-search-panel-header\",[30,0,[\"dataTestId\"]]],[12],[1,\"\\n  \"],[10,1],[14,0,\"text text--x-large search-panel__title\"],[15,\"data-test-search-panel-title\",[30,0,[\"dataTestId\"]]],[12],[1,\"\\n    \"],[1,[30,0,[\"title\"]]],[1,\"\\n  \"],[13],[1,\"\\n\"],[41,[30,0,[\"closable\"]],[[[1,\"    \"],[11,\"button\"],[24,0,\"button search-panel__close\"],[24,\"title\",\"Close this search panel\"],[16,\"data-test-search-panel-close\",[30,0,[\"dataTestId\"]]],[24,4,\"button\"],[4,[38,1],[\"click\",[30,0,[\"toggleSearchPanel\"]]],null],[12],[1,\"\\n      \"],[8,[39,2],null,[[\"@name\",\"@id\"],[\"close-big\",[28,[37,3],[[30,0,[\"dataTestId\"]],\"-close-icon\"],null]]],null],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null],[13],[1,\"\\n\"],[10,0],[14,0,\"search-panel__indented\"],[15,\"data-test-search-panel-indented\",[30,0,[\"dataTestId\"]]],[12],[1,\"\\n\"],[41,[48,[30,1]],[[[1,\"    \"],[18,1,[[28,[37,6],null,[[\"body\",\"tile\",\"footer\"],[[50,\"efs-search-panel-body\",0,null,[[\"dataTestId\"],[[30,0,[\"dataTestId\"]]]]],[50,\"efs-search-panel-tile\",0,null,[[\"name\"],[[30,0,[\"name\"]]]]],[50,\"efs-search-panel-footer\",0,null,[[\"dataTestId\"],[[30,0,[\"dataTestId\"]]]]]]]],[30,0,[\"dataTestId\"]]]],[1,\"\\n\"]],[]],[[[1,\"    \"],[8,[39,8],null,[[\"@dataTestId\"],[[30,0,[\"dataTestId\"]]]],null],[1,\"\\n    \"],[8,[39,9],null,[[\"@dataTestId\"],[[30,0,[\"dataTestId\"]]]],null],[1,\"\\n\"]],[]]],[13]],[\"&default\"],false,[\"if\",\"on\",\"efs-icon\",\"concat\",\"has-block\",\"yield\",\"hash\",\"component\",\"efs-search-panel-body\",\"efs-search-panel-footer\"]]",
    "moduleName": "efs-components/templates/components/efs-search-panel.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});