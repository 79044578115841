define("efs-components/utils/gen-uuid", ["exports", "@ember/object/internals"], function (_exports, _internals) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = genUuid;

  function genUuid(obj) {
    return (0, _internals.guidFor)(obj).slice(5);
  }
});