define("efs-components/components/efs-accordion-header", ["exports", "@ember-decorators/component", "@ember/object", "@ember/component", "efs-components/templates/components/efs-accordion-header"], function (_exports, _component, _object, _component2, _efsAccordionHeader) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _class2;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  let EfsAccordionHeader = (_dec = (0, _component.layout)(_efsAccordionHeader.default), _dec2 = (0, _component.classNames)("accordion__header"), _dec3 = (0, _component.classNameBindings)("isActive:accordion__header--active"), _dec4 = (0, _component.attributeBindings)("dataTestId:data-test-accordion-header", "for"), _dec5 = (0, _object.computed)("isActive"), _dec(_class = _dec2(_class = _dec3(_class = _dec4(_class = (_class2 = class EfsAccordionHeader extends _component2.default {
    constructor() {
      super(...arguments);

      // place simple properties and attributes in alphabetical order here:
      _defineProperty(this, "dataTestId", "default-accordion-header");

      _defineProperty(this, "isActive", false);

      _defineProperty(this, "for", "");

      _defineProperty(this, "title", "");
    }

    // place methods and actions here:
    onToggleRow() {} // place complex computed properties here:


    get iconName() {
      if (this.isActive) {
        return "triangle-arrow-down";
      }

      return "triangle-arrow-right";
    }

    toggleRow() {
      this.onToggleRow();
    }

  }, (_applyDecoratedDescriptor(_class2.prototype, "iconName", [_dec5], Object.getOwnPropertyDescriptor(_class2.prototype, "iconName"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "toggleRow", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "toggleRow"), _class2.prototype)), _class2)) || _class) || _class) || _class) || _class);
  _exports.default = EfsAccordionHeader;
});