define("efs-components/components/efs-accordion-content", ["exports", "@ember-decorators/component", "@ember/component"], function (_exports, _component, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  let EfsAccordionContent = (_dec = (0, _component.classNames)("accordion__content"), _dec2 = (0, _component.classNameBindings)("isActive:accordion__content--active"), _dec3 = (0, _component.attributeBindings)("dataTestId:data-test-accordion-content", "for"), _dec(_class = _dec2(_class = _dec3(_class = class EfsAccordionContent extends _component2.default {
    constructor() {
      super(...arguments);

      // place simple properties and attributes in alphabetical order here:
      _defineProperty(this, "dataTestId", "default-accordion-content");

      _defineProperty(this, "isActive", false);

      _defineProperty(this, "for", "");
    }

  }) || _class) || _class) || _class);
  _exports.default = EfsAccordionContent;
});