define("efs-components/components/efs-hint", ["exports", "@ember/component", "@ember/template-factory", "@ember/object", "@glimmer/component", "efs-components/utils/key-codes", "efs-components/utils/gen-uuid"], function (_exports, _component, _templateFactory, _object, _component2, _keyCodes, _genUuid) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div
    class="hint"
    data-test-hint={{this.dataTestId}}
    aria-describedby={{this.hintId}}
    name={{this.name}}
    role={{this.role}}
    tabindex={{this.tabindex}}
    ...attributes
    {{on "keyup" this.onKeyUp}}
    {{did-insert this.registerElement}}
  >
    <i
      class="hint__icon"
      aria-hidden="true"
      data-test-hint-icon={{this.dataTestId}}
    >
      <EfsIcon
        @name="lightbulb"
        @id={{concat this.dataTestId "-lightbulb-icon"}}
      />
    </i>
    <div
      id={{this.hintId}}
      class="hint__text"
      data-test-hint-text={{this.dataTestId}}
    >
      {{#if (has-block)}}
        {{yield}}
      {{else}}
        {{@message}}
      {{/if}}
    </div>
    <button
      type="button"
      class="hint__close"
      title="close this hint"
      data-test-hint-close-button={{this.dataTestId}}
      {{on "click" this.closeHint}}
    >
      <EfsIcon @name="close-small" @id={{concat this.dataTestId "-close-icon"}} />
    </button>
  </div>
  */
  {
    "id": "bcZjJIhX",
    "block": "[[[11,0],[24,0,\"hint\"],[16,\"data-test-hint\",[30,0,[\"dataTestId\"]]],[16,\"aria-describedby\",[30,0,[\"hintId\"]]],[16,3,[30,0,[\"name\"]]],[16,\"role\",[30,0,[\"role\"]]],[16,\"tabindex\",[30,0,[\"tabindex\"]]],[17,1],[4,[38,0],[\"keyup\",[30,0,[\"onKeyUp\"]]],null],[4,[38,1],[[30,0,[\"registerElement\"]]],null],[12],[1,\"\\n  \"],[10,\"i\"],[14,0,\"hint__icon\"],[14,\"aria-hidden\",\"true\"],[15,\"data-test-hint-icon\",[30,0,[\"dataTestId\"]]],[12],[1,\"\\n    \"],[8,[39,2],null,[[\"@name\",\"@id\"],[\"lightbulb\",[28,[37,3],[[30,0,[\"dataTestId\"]],\"-lightbulb-icon\"],null]]],null],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,0],[15,1,[30,0,[\"hintId\"]]],[14,0,\"hint__text\"],[15,\"data-test-hint-text\",[30,0,[\"dataTestId\"]]],[12],[1,\"\\n\"],[41,[48,[30,3]],[[[1,\"      \"],[18,3,null],[1,\"\\n\"]],[]],[[[1,\"      \"],[1,[30,2]],[1,\"\\n\"]],[]]],[1,\"  \"],[13],[1,\"\\n  \"],[11,\"button\"],[24,0,\"hint__close\"],[24,\"title\",\"close this hint\"],[16,\"data-test-hint-close-button\",[30,0,[\"dataTestId\"]]],[24,4,\"button\"],[4,[38,0],[\"click\",[30,0,[\"closeHint\"]]],null],[12],[1,\"\\n    \"],[8,[39,2],null,[[\"@name\",\"@id\"],[\"close-small\",[28,[37,3],[[30,0,[\"dataTestId\"]],\"-close-icon\"],null]]],null],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"&attrs\",\"@message\",\"&default\"],false,[\"on\",\"did-insert\",\"efs-icon\",\"concat\",\"if\",\"has-block\",\"yield\"]]",
    "moduleName": "efs-components/components/efs-hint.hbs",
    "isStrictMode": false
  });

  let EfsHintComponent = (_class = class EfsHintComponent extends _component2.default {
    constructor() {
      super(...arguments);

      _defineProperty(this, "hintId", `hint-${(0, _genUuid.default)(this)}`);
    }

    get dataTestId() {
      return this.args.dataTestId || this.args.name || this.hintId;
    }

    get message() {
      return this.args.message || "";
    }

    get name() {
      return this.args.name || "";
    }

    get role() {
      return this.args.role || "alert";
    }

    get tabindex() {
      return this.args.tabindex || 0;
    }

    onKeyUp(e) {
      let key = e.keyCode || e.which;

      if (_keyCodes.CLOSABLE_KEY_CODES.includes(key)) {
        this.onClose();
      }
    }

    closeHint() {
      this.onClose();
    }

    onClose() {
      if (this.args.onClose) {
        this.args.onClose();
        return;
      }

      this.element.remove();
    }

    registerElement(element) {
      this.element = element;
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "onKeyUp", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onKeyUp"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "closeHint", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "closeHint"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "registerElement", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "registerElement"), _class.prototype)), _class);
  _exports.default = EfsHintComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, EfsHintComponent);
});