define("efs-components/components/efs-radio", ["exports", "@ember-decorators/component", "@ember/object", "@ember/object/computed", "@ember/component", "efs-components/utils/gen-uuid", "efs-components/templates/components/efs-radio"], function (_exports, _component, _object, _computed, _component2, _genUuid, _efsRadio) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _class2, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let EfsRadio = (_dec = (0, _component.layout)(_efsRadio.default), _dec2 = (0, _component.attributeBindings)("dataTestId:data-test-radio", "name"), _dec3 = (0, _computed.alias)("name"), _dec4 = (0, _object.computed)("name", "options", "value"), _dec(_class = _dec2(_class = (_class2 = class EfsRadio extends _component2.default {
    constructor() {
      super(...arguments);

      _defineProperty(this, "name", "");

      // name is a required value for radio group - docs need to reflect that
      _defineProperty(this, "value", "");

      // place simple properties and attributes in alphabetical order here:
      _initializerDefineProperty(this, "dataTestId", _descriptor, this);
    }

    onChange() {}

    get radioOptions() {
      return this.options.map((option, index) => ({
        id: `radio-${(0, _genUuid.default)(this)}-${index}`,
        dataTestId: `${this.name}-option-${option.value}`,
        name: this.name,
        label: option.label,
        value: option.value,
        checked: option.value === this.value,
        disabled: option.disabled || false
      }));
    }

    get radioId() {
      return `radio-${(0, _genUuid.default)(this)}`;
    } // place methods and actions here:


    init() {
      super.init(...arguments);

      if (!this.name) {
        this.set("name", this.radioId);
      }

      if (!this.options) {
        this.set("options", []);
      }
    }

    radioChecked(value) {
      this.onChange(value);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "dataTestId", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "radioOptions", [_dec4], Object.getOwnPropertyDescriptor(_class2.prototype, "radioOptions"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "radioChecked", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "radioChecked"), _class2.prototype)), _class2)) || _class) || _class);
  _exports.default = EfsRadio;
});