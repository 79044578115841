define("efs-components/templates/components/efs-pagination", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "HPYsnD/g",
    "block": "[[[41,[30,0,[\"shouldShowPagination\"]],[[[41,[30,0,[\"shouldShowPrevious\"]],[[[1,\"    \"],[8,[39,1],null,[[\"@name\",\"@icon\",\"@class\",\"@click\"],[[28,[37,2],[\"previous-\",[30,0,[\"dataTestId\"]]],null],\"back\",\"pagination__previous\",[28,[37,3],[[30,0],\"previous\"],null]]],null],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[42,[28,[37,5],[[28,[37,5],[[30,0,[\"pageList\"]]],null]],null],null,[[[41,[30,1,[\"ellipse\"]],[[[1,\"      \"],[10,1],[14,0,\"pagination__ellipse\"],[15,\"data-test-pagination-ellipse\",[30,0,[\"dataTestId\"]]],[12],[1,\"\\n        ...\\n      \"],[13],[1,\"\\n\"]],[]],[[[1,\"      \"],[18,2,[[30,1],[30,0,[\"dataTestId\"]]]],[1,\"\\n\"]],[]]]],[1]],null],[1,\"\\n\"],[41,[30,0,[\"shouldShowNext\"]],[[[1,\"    \"],[8,[39,1],null,[[\"@name\",\"@icon\",\"@class\",\"@click\"],[[28,[37,2],[\"next-\",[30,0,[\"dataTestId\"]]],null],\"forward\",\"pagination__next\",[28,[37,3],[[30,0],\"next\"],null]]],null],[1,\"\\n\"]],[]],null]],[]],null]],[\"page\",\"&default\"],false,[\"if\",\"efs-button\",\"concat\",\"action\",\"each\",\"-track-array\",\"yield\"]]",
    "moduleName": "efs-components/templates/components/efs-pagination.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});