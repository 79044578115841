define("efs-components/templates/components/efs-table-body-more-cell", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "edSc1lOW",
    "block": "[[[11,\"button\"],[16,0,[30,0,[\"buttonClass\"]]],[24,3,\"more\"],[16,\"data-test-table-body-more-button\",[30,0,[\"dataTestId\"]]],[24,4,\"button\"],[4,[38,0],[\"click\",[28,[37,1],[[30,0],\"toggleActive\"],null]],null],[12],[1,\"\\n  \"],[8,[39,2],null,[[\"@name\",\"@id\"],[\"three-dots\",[28,[37,3],[[30,0,[\"dataTestId\"]],\"-icon\"],null]]],null],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[10,\"ul\"],[15,0,[30,0,[\"listClass\"]]],[15,\"data-test-table-body-more-list\",[30,0,[\"dataTestId\"]]],[12],[1,\"\\n  \"],[18,1,null],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"&default\"],false,[\"on\",\"action\",\"efs-icon\",\"concat\",\"yield\"]]",
    "moduleName": "efs-components/templates/components/efs-table-body-more-cell.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});