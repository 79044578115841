define("efs-components/components/efs-sidebar", ["exports", "@ember/component", "@ember/template-factory", "@ember/object", "@glimmer/component", "efs-components/utils/key-codes", "efs-components/utils/gen-uuid"], function (_exports, _component, _templateFactory, _object, _component2, _keyCodes, _genUuid) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div
    class="
      sidebar
      {{if this.isActive "sidebar--active"}}
      {{if this.fixedWidth "sidebar--fixed-width"}}
    "
    data-test-sidebar={{this.dataTestId}}
    name={{this.name}}
    {{! template-lint-disable no-invalid-interactive }}
    {{on "keyup" this.onKeyUp}}
  >
    {{#if this.isActive}}
      <div class="sidebar__header" data-test-sidebar-header={{this.dataTestId}}>
        <span
          class="text text--medium text--semi-bold sidebar__title"
          data-test-sidebar-title={{this.dataTestId}}
        >
          {{this.title}}
        </span>
        {{#if this.closable}}
          <button
            type="button"
            class="button sidebar__close"
            title="Close this search panel"
            data-test-sidebar-close={{this.dataTestId}}
            {{on "click" this.onClose}}
          >
            <EfsIcon @name="close-big" @id={{concat this.dataTestId "-close-icon"}} />
          </button>
        {{/if}}
      </div>
  
      {{yield}}
    {{/if}}
  </div>
  */
  {
    "id": "beuCNJtd",
    "block": "[[[11,0],[16,0,[29,[\"\\n    sidebar\\n    \",[52,[30,0,[\"isActive\"]],\"sidebar--active\"],\"\\n    \",[52,[30,0,[\"fixedWidth\"]],\"sidebar--fixed-width\"],\"\\n  \"]]],[16,\"data-test-sidebar\",[30,0,[\"dataTestId\"]]],[16,3,[30,0,[\"name\"]]],[4,[38,1],[\"keyup\",[30,0,[\"onKeyUp\"]]],null],[12],[1,\"\\n\"],[41,[30,0,[\"isActive\"]],[[[1,\"    \"],[10,0],[14,0,\"sidebar__header\"],[15,\"data-test-sidebar-header\",[30,0,[\"dataTestId\"]]],[12],[1,\"\\n      \"],[10,1],[14,0,\"text text--medium text--semi-bold sidebar__title\"],[15,\"data-test-sidebar-title\",[30,0,[\"dataTestId\"]]],[12],[1,\"\\n        \"],[1,[30,0,[\"title\"]]],[1,\"\\n      \"],[13],[1,\"\\n\"],[41,[30,0,[\"closable\"]],[[[1,\"        \"],[11,\"button\"],[24,0,\"button sidebar__close\"],[24,\"title\",\"Close this search panel\"],[16,\"data-test-sidebar-close\",[30,0,[\"dataTestId\"]]],[24,4,\"button\"],[4,[38,1],[\"click\",[30,0,[\"onClose\"]]],null],[12],[1,\"\\n          \"],[8,[39,2],null,[[\"@name\",\"@id\"],[\"close-big\",[28,[37,3],[[30,0,[\"dataTestId\"]],\"-close-icon\"],null]]],null],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]],null],[1,\"    \"],[13],[1,\"\\n\\n    \"],[18,1,null],[1,\"\\n\"]],[]],null],[13]],[\"&default\"],false,[\"if\",\"on\",\"efs-icon\",\"concat\",\"yield\"]]",
    "moduleName": "efs-components/components/efs-sidebar.hbs",
    "isStrictMode": false
  });

  let EfsSidebarComponent = (_class = class EfsSidebarComponent extends _component2.default {
    constructor() {
      super(...arguments);

      _defineProperty(this, "sidebarId", `sidebar-${(0, _genUuid.default)(this)}`);
    }

    get isActive() {
      return this.args.isActive || false;
    }

    get closable() {
      return this.args.closable !== false;
    }

    get fixedWidth() {
      return this.args.fixedWidth || false;
    }

    get name() {
      return this.args.name || "";
    }

    get title() {
      return this.args.title || "Sidebar";
    }

    get dataTestId() {
      return this.args.dataTestId || this.name || this.sidebarId;
    }

    onClose() {
      if (this.args.onClose) {
        this.args.onClose();
      }
    }

    onKeyUp(e) {
      let key = e.keyCode || e.which;

      if (_keyCodes.CLOSABLE_KEY_CODES.includes(key)) {
        this.onClose();
      }
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "onClose", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onClose"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onKeyUp", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onKeyUp"), _class.prototype)), _class);
  _exports.default = EfsSidebarComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, EfsSidebarComponent);
});