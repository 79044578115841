define("efs-components/templates/components/efs-code-block", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "Tt5hbqzE",
    "block": "[[[10,\"code\"],[15,\"data-test-codeblock-code\",[30,0,[\"dataTestId\"]]],[12],[18,1,null],[13],[1,\"\\n\"],[10,1],[14,0,\"codeblock__type\"],[15,\"data-test-codeblock-type\",[30,0,[\"dataTestId\"]]],[12],[1,[30,0,[\"type\"]]],[13],[1,\"\\n\"]],[\"&default\"],false,[\"yield\"]]",
    "moduleName": "efs-components/templates/components/efs-code-block.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});