define("efs-components/components/ax-horizontal-input", ["exports", "@ember/component", "@ember/template-factory", "efs-components/components/ax-input"], function (_exports, _component, _templateFactory, _axInput) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.AxHorizontalInputComponent = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class={{this.wrapperClassName}} data-test-text-field={{this.dataTestId}}>
    {{#if this.hasLabel}}
      <label
        class="text-field-h__label"
        for={{this.inputId}}
        data-test-text-field-label={{this.dataTestId}}
      >
        <span
          data-test-text-field-label-text={{this.dataTestId}}
        >
          {{@label}}
        </span>
        {{#if this.isRequired}}
          <span
            class="text-field-h__required"
            data-test-text-field-required={{this.dataTestId}}
          >
            *
          </span>
        {{/if}}
      </label>
    {{/if}}
    <div
      class="text-field-h__input-container"
      data-test-text-field-container={{this.dataTestId}}
    >
      <input
        class={{this.inputClassName}}
        data-test-text-field-input={{this.dataTestId}}
        id={{this.inputId}}
        name={{@name}}
        required={{if this.isRequired "required"}}
        ...attributes
      />
      {{#if this.isIconVisible}}
        <span
          class={{this.iconClassName}}
          for={{this.inputId}}
          data-test-text-field-icon={{this.dataTestId}}
        >
          <EfsIcon @name={{this.iconName}} @id={{this.iconId}} />
        </span>
      {{/if}}
    </div>
    {{#if this.hasMessage}}
      <span
        class={{this.messageClassName}}
        data-test-text-field-message={{this.dataTestId}}
        for={{this.inputId}}
      >
        {{@message}}
      </span>
    {{/if}}
  </div>
  
  */
  {
    "id": "jf/TpaGs",
    "block": "[[[10,0],[15,0,[30,0,[\"wrapperClassName\"]]],[15,\"data-test-text-field\",[30,0,[\"dataTestId\"]]],[12],[1,\"\\n\"],[41,[30,0,[\"hasLabel\"]],[[[1,\"    \"],[10,\"label\"],[14,0,\"text-field-h__label\"],[15,\"for\",[30,0,[\"inputId\"]]],[15,\"data-test-text-field-label\",[30,0,[\"dataTestId\"]]],[12],[1,\"\\n      \"],[10,1],[15,\"data-test-text-field-label-text\",[30,0,[\"dataTestId\"]]],[12],[1,\"\\n        \"],[1,[30,1]],[1,\"\\n      \"],[13],[1,\"\\n\"],[41,[30,0,[\"isRequired\"]],[[[1,\"        \"],[10,1],[14,0,\"text-field-h__required\"],[15,\"data-test-text-field-required\",[30,0,[\"dataTestId\"]]],[12],[1,\"\\n          *\\n        \"],[13],[1,\"\\n\"]],[]],null],[1,\"    \"],[13],[1,\"\\n\"]],[]],null],[1,\"  \"],[10,0],[14,0,\"text-field-h__input-container\"],[15,\"data-test-text-field-container\",[30,0,[\"dataTestId\"]]],[12],[1,\"\\n    \"],[11,\"input\"],[16,0,[30,0,[\"inputClassName\"]]],[16,\"data-test-text-field-input\",[30,0,[\"dataTestId\"]]],[16,1,[30,0,[\"inputId\"]]],[16,3,[30,2]],[16,\"required\",[52,[30,0,[\"isRequired\"]],\"required\"]],[17,3],[12],[13],[1,\"\\n\"],[41,[30,0,[\"isIconVisible\"]],[[[1,\"      \"],[10,1],[15,0,[30,0,[\"iconClassName\"]]],[15,\"for\",[30,0,[\"inputId\"]]],[15,\"data-test-text-field-icon\",[30,0,[\"dataTestId\"]]],[12],[1,\"\\n        \"],[8,[39,1],null,[[\"@name\",\"@id\"],[[30,0,[\"iconName\"]],[30,0,[\"iconId\"]]]],null],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null],[1,\"  \"],[13],[1,\"\\n\"],[41,[30,0,[\"hasMessage\"]],[[[1,\"    \"],[10,1],[15,0,[30,0,[\"messageClassName\"]]],[15,\"data-test-text-field-message\",[30,0,[\"dataTestId\"]]],[15,\"for\",[30,0,[\"inputId\"]]],[12],[1,\"\\n      \"],[1,[30,4]],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null],[13],[1,\"\\n\"]],[\"@label\",\"@name\",\"&attrs\",\"@message\"],false,[\"if\",\"efs-icon\"]]",
    "moduleName": "efs-components/components/ax-horizontal-input.hbs",
    "isStrictMode": false
  });

  class AxHorizontalInputComponent extends _axInput.default {
    get iconClassName() {
      const classNames = ["text-field-h__icon"];

      if (this.isError) {
        classNames.push("text-field-h--error__icon");
      } else if (this.isInvalid) {
        classNames.push("text-field-h--invalid__icon");
      }

      return classNames.join(" ");
    }

    get inputClassName() {
      const classNames = ["text-field-h__input"];

      if (this.isError) {
        classNames.push("text-field-h--error__input");
      } else if (this.isInvalid) {
        classNames.push("text-field-h--invalid__input");
      }

      return classNames.join(" ");
    }

    get messageClassName() {
      const classNames = ["text-field-h__message"];

      if (this.isError) {
        classNames.push("text-field-h--error__message");
      } else if (this.isInvalid) {
        classNames.push("text-field-h--invalid__message");
      }

      return classNames.join(" ");
    }

    get wrapperClassName() {
      const classNames = ["text-field-h"];

      if (this.isError) {
        classNames.push("text-field-h--error");
      } else if (this.isInvalid) {
        classNames.push("text-field-h--invalid");
      }

      return classNames.join(" ");
    }

  }

  _exports.AxHorizontalInputComponent = AxHorizontalInputComponent;

  var _default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, AxHorizontalInputComponent);

  _exports.default = _default;
});