define("efs-components/components/efs-tooltip", ["exports", "@ember-decorators/component", "@ember/object", "@ember/component", "efs-components/utils/gen-uuid", "efs-components/templates/components/efs-tooltip"], function (_exports, _component, _object, _component2, _genUuid, _efsTooltip) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _class2;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  let EfsTooltip = (_dec = (0, _component.layout)(_efsTooltip.default), _dec2 = (0, _component.tagName)("span"), _dec3 = (0, _component.classNames)("tooltip"), _dec4 = (0, _component.attributeBindings)("dataTestId:data-test-tooltip", "name"), _dec5 = (0, _object.computed)("active", "sticky", "system"), _dec(_class = _dec2(_class = _dec3(_class = _dec4(_class = (_class2 = class EfsTooltip extends _component2.default {
    constructor() {
      super(...arguments);

      // place simple properties and attributes in alphabetical order here:
      _defineProperty(this, "active", false);

      _defineProperty(this, "dataTestId", null);

      _defineProperty(this, "sticky", false);

      _defineProperty(this, "system", false);

      _defineProperty(this, "name", "");
    }

    // place complex computed properties here:
    get balloonClassName() {
      let base = "tooltip__balloon";

      if (this.system) {
        base += " tooltip__balloon--system";
      }

      if (this.active || this.sticky) {
        return base + " tooltip__balloon--active";
      }

      return base;
    }

    get tooltipId() {
      return `tooltip-${(0, _genUuid.default)(this)}`;
    } // place methods and actions here:


    init() {
      super.init(...arguments);

      if (!this.dataTestId) {
        if (this.name) {
          this.set("dataTestId", this.name);
        } else {
          this.set("dataTestId", this.tooltipId);
        }
      }
    }

    didInsertElement() {
      super.didInsertElement(...arguments);
      this.element.addEventListener("mouseenter", this.handleMouseEnter);
      this.element.addEventListener("mouseleave", this.handleMouseLeave);
    }

    willDestroyElement() {
      super.willDestroyElement(...arguments);
      this.element.removeEventListener("mouseenter", this.handleMouseEnter);
      this.element.removeEventListener("mouseleave", this.handleMouseLeave);
    }

    handleMouseEnter() {
      this.set("active", true);
    }

    handleMouseLeave() {
      this.set("active", false);
    }

    toggleActive() {
      this.set("active", !this.active);
    }

    toggleSticky() {
      this.set("sticky", !this.sticky);
    }

    focusIn() {
      this.set("active", true);
    }

    focusOut() {
      this.set("active", false);
      this.set("sticky", false);
    }

  }, (_applyDecoratedDescriptor(_class2.prototype, "balloonClassName", [_dec5], Object.getOwnPropertyDescriptor(_class2.prototype, "balloonClassName"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "handleMouseEnter", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "handleMouseEnter"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "handleMouseLeave", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "handleMouseLeave"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "toggleActive", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "toggleActive"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "toggleSticky", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "toggleSticky"), _class2.prototype)), _class2)) || _class) || _class) || _class) || _class);
  _exports.default = EfsTooltip;
});