define("efs-components/components/efs-calendar", ["exports", "@ember-decorators/component", "@ember/object", "@ember/service", "@ember/object/computed", "date-fns/getDaysInMonth", "date-fns/getDay", "date-fns/format", "date-fns/addMonths", "date-fns/subMonths", "date-fns/isAfter", "date-fns/isBefore", "date-fns/isSaturday", "date-fns/isFriday", "date-fns/isFuture", "date-fns/startOfMonth", "date-fns/endOfMonth", "date-fns/parseISO", "date-fns/isWithinInterval", "@ember/component", "efs-components/templates/components/efs-calendar", "efs-components/utils/gen-uuid"], function (_exports, _component, _object, _service, _computed, _getDaysInMonth, _getDay, _format, _addMonths, _subMonths, _isAfter, _isBefore, _isSaturday, _isFriday, _isFuture, _startOfMonth, _endOfMonth, _parseISO, _isWithinInterval, _component2, _efsCalendar, _genUuid) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.YEAR_FORMAT = _exports.SIX_WEEK_MAXIMUM_DAYS = _exports.MONTH_MAP = _exports.MONTH_FORMAT = _exports.INACTIVE_STATUS = _exports.FIVE_WEEK_MAXIMUM_DAYS = _exports.DAY_FORMAT = _exports.DATE_FORMAT = _exports.CLASS_NAME = _exports.CALENDAR_DAY_SELECTED_CLASS_NAME = _exports.CALENDAR_DAY_INACTIVE_CLASS_NAME = _exports.CALENDAR_DAY_DISABLED_CLASS_NAME = _exports.CALENDAR_DAY_CLASS_NAME = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _class, _class2, _descriptor, _descriptor2, _descriptor3, _descriptor4;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const MONTH_MAP = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
  _exports.MONTH_MAP = MONTH_MAP;
  const CLASS_NAME = "calendar";
  _exports.CLASS_NAME = CLASS_NAME;
  const CALENDAR_DAY_CLASS_NAME = "calendar-day";
  _exports.CALENDAR_DAY_CLASS_NAME = CALENDAR_DAY_CLASS_NAME;
  const CALENDAR_DAY_DISABLED_CLASS_NAME = "calendar-day--disabled";
  _exports.CALENDAR_DAY_DISABLED_CLASS_NAME = CALENDAR_DAY_DISABLED_CLASS_NAME;
  const CALENDAR_DAY_INACTIVE_CLASS_NAME = "calendar-day--inactive";
  _exports.CALENDAR_DAY_INACTIVE_CLASS_NAME = CALENDAR_DAY_INACTIVE_CLASS_NAME;
  const CALENDAR_DAY_SELECTED_CLASS_NAME = "calendar-day--selected";
  _exports.CALENDAR_DAY_SELECTED_CLASS_NAME = CALENDAR_DAY_SELECTED_CLASS_NAME;
  const INACTIVE_STATUS = "inactive";
  _exports.INACTIVE_STATUS = INACTIVE_STATUS;
  const FIVE_WEEK_MAXIMUM_DAYS = 35;
  _exports.FIVE_WEEK_MAXIMUM_DAYS = FIVE_WEEK_MAXIMUM_DAYS;
  const SIX_WEEK_MAXIMUM_DAYS = 42;
  _exports.SIX_WEEK_MAXIMUM_DAYS = SIX_WEEK_MAXIMUM_DAYS;
  const DATE_FORMAT = "yyyy-MM-dd";
  _exports.DATE_FORMAT = DATE_FORMAT;
  const YEAR_FORMAT = "yyyy";
  _exports.YEAR_FORMAT = YEAR_FORMAT;
  const MONTH_FORMAT = "MM";
  _exports.MONTH_FORMAT = MONTH_FORMAT;
  const DAY_FORMAT = "dd";
  _exports.DAY_FORMAT = DAY_FORMAT;
  let EfsCalendar = (_dec = (0, _component.layout)(_efsCalendar.default), _dec2 = (0, _component.classNames)(CLASS_NAME), _dec3 = (0, _component.attributeBindings)("dataTestId:data-test-calendar"), _dec4 = (0, _computed.notEmpty)("rangeEndDate"), _dec5 = (0, _computed.notEmpty)("reports"), _dec6 = (0, _computed.notEmpty)("rangeStartDate"), _dec7 = (0, _object.computed)("date"), _dec8 = (0, _object.computed)("router.currentRouteName", "routeName"), _dec9 = (0, _object.computed)("date"), _dec10 = (0, _object.computed)("month"), _dec11 = (0, _object.computed)("monthIndex"), _dec12 = (0, _object.computed)("date"), _dec13 = (0, _object.computed)("year", "date", "monthIndex", "hasEndRange", "hasReports", "rangeEndDate"), _dec14 = (0, _object.computed)("year", "date", "monthIndex", "hasStartRange", "hasReports", "rangeStartDate"), _dec15 = (0, _object.computed)("month", "monthIndex", "rangeEndDate", "rangeStartDate", "reports", "shouldDisableDays", "shouldDisableNoReportsDays", "year"), _dec(_class = _dec2(_class = _dec3(_class = (_class2 = class EfsCalendar extends _component2.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "router", _descriptor, this);

      _defineProperty(this, "dataTestId", null);

      _defineProperty(this, "date", null);

      _defineProperty(this, "name", "");

      _defineProperty(this, "nextMonthDay", 1);

      _defineProperty(this, "previousMonthDay", 1);

      _defineProperty(this, "rangeEndDate", "");

      _defineProperty(this, "rangeStartDate", "");

      _defineProperty(this, "reports", null);

      _defineProperty(this, "routeName", "");

      _defineProperty(this, "shouldDisableDays", false);

      _defineProperty(this, "shouldDisableNoReportsDays", false);

      _defineProperty(this, "shouldShowDisabledTooltip", false);

      _initializerDefineProperty(this, "hasEndRange", _descriptor2, this);

      _initializerDefineProperty(this, "hasReports", _descriptor3, this);

      _initializerDefineProperty(this, "hasStartRange", _descriptor4, this);
    }

    get calendarId() {
      return `calendar-${(0, _genUuid.default)(this)}`;
    }

    get day() {
      return (0, _format.default)((0, _parseISO.default)(this.date), DAY_FORMAT);
    }

    get linkRoute() {
      return this.routeName ? this.routeName : this.router.currentRouteName;
    }

    get month() {
      return (0, _format.default)((0, _parseISO.default)(this.date), MONTH_FORMAT);
    }

    get monthIndex() {
      return this.month - 1;
    }

    get monthString() {
      return MONTH_MAP[this.monthIndex] || MONTH_MAP[0];
    }

    get year() {
      return (0, _format.default)((0, _parseISO.default)(this.date), YEAR_FORMAT);
    }

    get shouldShowNext() {
      if (this.hasReports && this.hasEndRange) {
        let monthTestDate = new Date(this.year, this.monthIndex, 15);
        return (0, _isAfter.default)((0, _parseISO.default)(this.rangeEndDate), (0, _endOfMonth.default)(monthTestDate));
      }

      if (this.hasEndRange) {
        return (0, _isAfter.default)((0, _parseISO.default)(this.rangeEndDate), (0, _endOfMonth.default)((0, _parseISO.default)(this.date)));
      }

      return true;
    }

    get shouldShowPrevious() {
      if (this.hasReports && this.hasStartRange) {
        let monthTestDate = new Date(this.year, this.monthIndex, 15);
        return (0, _isBefore.default)((0, _parseISO.default)(this.rangeStartDate), (0, _startOfMonth.default)(monthTestDate));
      }

      if (this.hasStartRange) {
        return (0, _isBefore.default)((0, _parseISO.default)(this.rangeStartDate), (0, _startOfMonth.default)((0, _parseISO.default)(this.date)));
      }

      return true;
    }

    get calendarDays() {
      let previousMonth = new Date(this.year, this.monthIndex - 1);
      let daysInPreviousMonth = (0, _getDaysInMonth.default)(previousMonth);
      let firstDay = (0, _getDay.default)(new Date(this.year, this.monthIndex, 1));
      let days = []; // prepend days from last month

      while (firstDay > 0) {
        const day = daysInPreviousMonth;
        let date = new Date(previousMonth.getFullYear(), previousMonth.getMonth(), day);
        days.unshift({
          label: day,
          date: (0, _format.default)(date, DATE_FORMAT),
          isCurrentMonth: false,
          baseClassName: `${CALENDAR_DAY_CLASS_NAME} ${CALENDAR_DAY_INACTIVE_CLASS_NAME}`,
          activeClassName: CALENDAR_DAY_SELECTED_CLASS_NAME,
          status: INACTIVE_STATUS
        });
        daysInPreviousMonth -= 1;
        firstDay -= 1;
      }

      let reportDate = new Date(this.year, this.monthIndex);
      let daysInMonth = (0, _getDaysInMonth.default)(reportDate);

      for (let day = 1; day <= daysInMonth; day += 1) {
        let dayDate = new Date(this.year, this.monthIndex, day);
        let formattedDate = (0, _format.default)(dayDate, DATE_FORMAT);
        let report = this.reports.find(r => {
          let reportDate = (0, _format.default)(new Date(r.timestamp), DATE_FORMAT);
          return reportDate === formattedDate;
        });
        let baseClassName = report ? `${CALENDAR_DAY_CLASS_NAME} ${CALENDAR_DAY_CLASS_NAME}--${report.status}` : CALENDAR_DAY_CLASS_NAME;
        let dateIsOutOfRange = false;

        if (this.rangeStartDate && this.rangeEndDate) {
          const start = (0, _parseISO.default)(this.rangeStartDate);
          const end = (0, _parseISO.default)(this.rangeEndDate);
          dateIsOutOfRange = (0, _isWithinInterval.default)(dayDate, {
            start,
            end
          }) === false;
        }

        let isFutureDate = (0, _isFuture.default)(dayDate);
        let isNoReportsDate = this.shouldDisableNoReportsDays ? !report : false;
        let dateIsNotPermitted = dateIsOutOfRange || isFutureDate || isNoReportsDate;
        days.push({
          label: day,
          date: formattedDate,
          isCurrentMonth: true,
          baseClassName,
          activeClassName: CALENDAR_DAY_SELECTED_CLASS_NAME,
          status: report ? report.status : "",
          disabledClassName: `${CALENDAR_DAY_CLASS_NAME} ${CALENDAR_DAY_DISABLED_CLASS_NAME}`,
          dateIsDisabled: this.shouldDisableDays ? dateIsNotPermitted : false
        });
      }

      let hasThirtyPlusDays = daysInMonth >= 30;
      let startsOnFriday = (0, _isFriday.default)(new Date(this.year, this.monthIndex, 1));
      let startsOnSaturday = (0, _isSaturday.default)(new Date(this.year, this.monthIndex, 1));
      let doesntEndOnSaturday = (0, _isSaturday.default)(new Date(this.year, this.monthIndex, daysInMonth)) === false;
      let maximumDays = hasThirtyPlusDays && (startsOnFriday || startsOnSaturday) && doesntEndOnSaturday ? SIX_WEEK_MAXIMUM_DAYS : FIVE_WEEK_MAXIMUM_DAYS; // add next months days if needed...

      let nextMonthDay = 1;

      while (days.length < maximumDays) {
        const day = nextMonthDay;
        days.push({
          label: day,
          date: (0, _format.default)(new Date(this.year, this.monthIndex + 1, day), DATE_FORMAT),
          isCurrentMonth: false,
          baseClassName: `${CALENDAR_DAY_CLASS_NAME} ${CALENDAR_DAY_INACTIVE_CLASS_NAME}`,
          activeClassName: CALENDAR_DAY_SELECTED_CLASS_NAME,
          status: INACTIVE_STATUS
        });
        nextMonthDay += 1;
      }

      return days;
    }

    init() {
      super.init(...arguments);

      if (!this.reports) {
        this.reports = [];
      }

      if (!this.dataTestId) {
        this.dataTestId = this.name ? this.name : this.calendarId;
      }

      this.date = this.date || (0, _format.default)(new Date(), DAY_FORMAT);
    }

    goForwardAction() {
      let monthIndex = this.month >= 1 ? this.month - 1 : 11;
      let dt = new Date(this.year, monthIndex, this.nextMonthDay);
      let nextMonth = (0, _addMonths.default)(dt, 1);
      this.goForward((0, _format.default)(nextMonth, DATE_FORMAT));
    }

    goBackAction() {
      let monthIndex = this.month >= 1 ? this.month - 1 : 11;
      let dt = new Date(this.year, monthIndex, this.previousMonthDay);
      let previousMonth = (0, _subMonths.default)(dt, 1);
      this.goBack((0, _format.default)(previousMonth, DATE_FORMAT));
    }

    onDaySelectAction(date, event) {
      event.preventDefault();
      this.onDaySelect(date);
    }

    goForward(date) {
      this.set("date", date);
    }

    goBack(date) {
      this.set("date", date);
    }

    onDaySelect(date) {
      this.set("date", date);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "router", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "hasEndRange", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class2.prototype, "hasReports", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class2.prototype, "hasStartRange", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "day", [_dec7], Object.getOwnPropertyDescriptor(_class2.prototype, "day"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "linkRoute", [_dec8], Object.getOwnPropertyDescriptor(_class2.prototype, "linkRoute"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "month", [_dec9], Object.getOwnPropertyDescriptor(_class2.prototype, "month"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "monthIndex", [_dec10], Object.getOwnPropertyDescriptor(_class2.prototype, "monthIndex"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "monthString", [_dec11], Object.getOwnPropertyDescriptor(_class2.prototype, "monthString"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "year", [_dec12], Object.getOwnPropertyDescriptor(_class2.prototype, "year"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "shouldShowNext", [_dec13], Object.getOwnPropertyDescriptor(_class2.prototype, "shouldShowNext"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "shouldShowPrevious", [_dec14], Object.getOwnPropertyDescriptor(_class2.prototype, "shouldShowPrevious"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "calendarDays", [_dec15], Object.getOwnPropertyDescriptor(_class2.prototype, "calendarDays"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "goForwardAction", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "goForwardAction"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "goBackAction", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "goBackAction"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "onDaySelectAction", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "onDaySelectAction"), _class2.prototype)), _class2)) || _class) || _class) || _class);
  _exports.default = EfsCalendar;
});