define("efs-components/components/ax-checkbox", ["exports", "@ember/component", "@ember/template-factory", "@ember/object", "@glimmer/component", "efs-components/utils/gen-uuid"], function (_exports, _component, _templateFactory, _object, _component2, _genUuid) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <label
    class={{this.labelClassName}}
    aria-checked={{this.ariaChecked}}
    aria-disabled={{this.ariaDisabled}}
    data-test-checkbox={{this.dataTestId}}
    for={{this.checkId}}
    name={{@name}}
    {{on "change" this.onChangeAction}}
    {{on "click" this.onClickAction}}
  >
    <span
      class="text checkbox__label-text"
      data-test-checkbox-label={{this.dataTestId}}
    >
      {{#if (has-block)}}
        {{yield}}
      {{else}}
        {{@label}}
      {{/if}}
    </span>
  
    <input
      checked={{@checked}}
      class="checkbox__input"
      data-test-checkbox-input={{this.dataTestId}}
      disabled={{@disabled}}
      id={{this.checkId}}
      name={{@name}}
      role="checkbox"
      type="checkbox"
      ...attributes
    />
  
    <div
      class={{this.indicatorClassName}}
      data-test-checkbox-indicator={{this.dataTestId}}
    ></div>
  </label>
  */
  {
    "id": "S9LSkrQ0",
    "block": "[[[11,\"label\"],[16,0,[30,0,[\"labelClassName\"]]],[16,\"aria-checked\",[30,0,[\"ariaChecked\"]]],[16,\"aria-disabled\",[30,0,[\"ariaDisabled\"]]],[16,\"data-test-checkbox\",[30,0,[\"dataTestId\"]]],[16,\"for\",[30,0,[\"checkId\"]]],[16,3,[30,1]],[4,[38,0],[\"change\",[30,0,[\"onChangeAction\"]]],null],[4,[38,0],[\"click\",[30,0,[\"onClickAction\"]]],null],[12],[1,\"\\n  \"],[10,1],[14,0,\"text checkbox__label-text\"],[15,\"data-test-checkbox-label\",[30,0,[\"dataTestId\"]]],[12],[1,\"\\n\"],[41,[48,[30,6]],[[[1,\"      \"],[18,6,null],[1,\"\\n\"]],[]],[[[1,\"      \"],[1,[30,2]],[1,\"\\n\"]],[]]],[1,\"  \"],[13],[1,\"\\n\\n  \"],[11,\"input\"],[16,\"checked\",[30,3]],[24,0,\"checkbox__input\"],[16,\"data-test-checkbox-input\",[30,0,[\"dataTestId\"]]],[16,\"disabled\",[30,4]],[16,1,[30,0,[\"checkId\"]]],[16,3,[30,1]],[24,\"role\",\"checkbox\"],[24,4,\"checkbox\"],[17,5],[12],[13],[1,\"\\n\\n  \"],[10,0],[15,0,[30,0,[\"indicatorClassName\"]]],[15,\"data-test-checkbox-indicator\",[30,0,[\"dataTestId\"]]],[12],[13],[1,\"\\n\"],[13]],[\"@name\",\"@label\",\"@checked\",\"@disabled\",\"&attrs\",\"&default\"],false,[\"on\",\"if\",\"has-block\",\"yield\"]]",
    "moduleName": "efs-components/components/ax-checkbox.hbs",
    "isStrictMode": false
  });

  let AxCheckboxComponent = (_class = class AxCheckboxComponent extends _component2.default {
    get ariaChecked() {
      // this aligns with a11y/aria standards
      return this.args.partial ? "mixed" : (this.args.checked || false).toString();
    }

    get ariaDisabled() {
      return (this.args.disabled || false).toString();
    }

    get checkId() {
      return `checkbox-${(0, _genUuid.default)(this)}`;
    }

    get dataTestId() {
      return this.args.name || this.checkId;
    }

    get indicatorClassName() {
      if (this.args.partial) {
        return "checkbox__indicator checkbox--partial__indicator";
      }

      return "checkbox__indicator";
    }

    get labelClassName() {
      const classNames = ["checkbox"];

      if (this.args.disabled) {
        classNames.push("checkbox--disabled");
      }

      if (this.args.partial) {
        classNames.push("checkbox--partial");
      }

      return classNames.join(" ");
    }

    onChangeAction(event) {
      if (this.args.onCheck) {
        this.args.onCheck(event.target.checked);
      }

      if (this.args.onChange) {
        this.args.onChange(event);
      }
    }

    onClickAction(event) {
      if (event.target.id !== this.checkId) {
        event.stopPropagation();
      }
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "onChangeAction", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onChangeAction"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onClickAction", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onClickAction"), _class.prototype)), _class);
  _exports.default = AxCheckboxComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, AxCheckboxComponent);
});