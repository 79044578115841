define("efs-components/templates/components/efs-layout-panel-content-footer", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "abc7hhbh",
    "block": "[[[41,[48,[30,1]],[[[1,\"  \"],[18,1,null],[1,\"\\n\"]],[]],[[[1,\"  \"],[10,\"footer\"],[14,5,\"text-align:center\"],[15,\"data-test-content-footer\",[30,0,[\"dataTestId\"]]],[12],[1,\"\\n    \"],[10,2],[14,0,\"text text--small\"],[15,\"data-test-contet-footer-copyright\",[30,0,[\"dataTestId\"]]],[12],[1,\"\\n      © \"],[1,[30,0,[\"copyrightYear\"]]],[1,\" Axcient All Rights Reserved |\\n      \"],[10,3],[14,0,\"link link--small\"],[15,\"data-test-anchor\",[29,[[30,0,[\"dataTestId\"]],\" footer notices\"]]],[14,6,\"https://axcient.com/legal-notices\"],[14,\"target\",\"_blank\"],[14,\"rel\",\"nofollow noopener noreferrer\"],[12],[1,\"\\n        Legal Notices\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]]]],[\"&default\"],false,[\"if\",\"has-block\",\"yield\"]]",
    "moduleName": "efs-components/templates/components/efs-layout-panel-content-footer.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});