define("efs-components/components/ax-horizontal-textarea", ["exports", "@ember/component", "@ember/template-factory", "efs-components/components/ax-textarea"], function (_exports, _component, _templateFactory, _axTextarea) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.AxHorizontalTextareaComponent = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class={{this.wrapperClassName}} data-test-text-area={{this.dataTestId}}>
    {{#if this.hasLabel}}
      <label
        class="textarea-h__label"
        for={{this.textareaId}}
        data-test-text-area-label={{this.dataTestId}}
      >
        <span
          data-test-text-area-label-text={{this.dataTestId}}
        >
          {{@label}}
        </span>
        {{#if this.isRequired}}
          <span
            class="textarea-h__required"
            data-test-text-area-required={{this.dataTestId}}
          >
            *
          </span>
        {{/if}}
      </label>
    {{/if}}
    <div
      class="textarea-h__container"
      data-test-text-area-container={{this.dataTestId}}
    >
      <textarea
        class={{this.textareaClassName}}
        data-test-text-area-textarea={{this.dataTestId}}
        id={{this.textareaId}}
        name={{@name}}
        required={{if this.isRequired "required"}}
        ...attributes
      />
      {{#if this.isIconVisible}}
        <span
          class={{this.iconClassName}}
          for={{this.textareaId}}
          data-test-text-area-icon={{this.dataTestId}}
        >
          <EfsIcon @name={{this.iconName}} @id={{this.iconId}} />
        </span>
      {{/if}}
    </div>
    {{#if this.hasMessage}}
      <span
        class={{this.messageClassName}}
        data-test-text-area-message={{this.dataTestId}}
        for={{this.textareaId}}
      >
        {{@message}}
      </span>
    {{/if}}
  </div>
  
  */
  {
    "id": "j67v36AZ",
    "block": "[[[10,0],[15,0,[30,0,[\"wrapperClassName\"]]],[15,\"data-test-text-area\",[30,0,[\"dataTestId\"]]],[12],[1,\"\\n\"],[41,[30,0,[\"hasLabel\"]],[[[1,\"    \"],[10,\"label\"],[14,0,\"textarea-h__label\"],[15,\"for\",[30,0,[\"textareaId\"]]],[15,\"data-test-text-area-label\",[30,0,[\"dataTestId\"]]],[12],[1,\"\\n      \"],[10,1],[15,\"data-test-text-area-label-text\",[30,0,[\"dataTestId\"]]],[12],[1,\"\\n        \"],[1,[30,1]],[1,\"\\n      \"],[13],[1,\"\\n\"],[41,[30,0,[\"isRequired\"]],[[[1,\"        \"],[10,1],[14,0,\"textarea-h__required\"],[15,\"data-test-text-area-required\",[30,0,[\"dataTestId\"]]],[12],[1,\"\\n          *\\n        \"],[13],[1,\"\\n\"]],[]],null],[1,\"    \"],[13],[1,\"\\n\"]],[]],null],[1,\"  \"],[10,0],[14,0,\"textarea-h__container\"],[15,\"data-test-text-area-container\",[30,0,[\"dataTestId\"]]],[12],[1,\"\\n    \"],[11,\"textarea\"],[16,0,[30,0,[\"textareaClassName\"]]],[16,\"data-test-text-area-textarea\",[30,0,[\"dataTestId\"]]],[16,1,[30,0,[\"textareaId\"]]],[16,3,[30,2]],[16,\"required\",[52,[30,0,[\"isRequired\"]],\"required\"]],[17,3],[12],[13],[1,\"\"],[41,[30,0,[\"isIconVisible\"]],[[[1,\"      \"],[10,1],[15,0,[30,0,[\"iconClassName\"]]],[15,\"for\",[30,0,[\"textareaId\"]]],[15,\"data-test-text-area-icon\",[30,0,[\"dataTestId\"]]],[12],[1,\"\\n        \"],[8,[39,1],null,[[\"@name\",\"@id\"],[[30,0,[\"iconName\"]],[30,0,[\"iconId\"]]]],null],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null],[1,\"  \"],[13],[1,\"\\n\"],[41,[30,0,[\"hasMessage\"]],[[[1,\"    \"],[10,1],[15,0,[30,0,[\"messageClassName\"]]],[15,\"data-test-text-area-message\",[30,0,[\"dataTestId\"]]],[15,\"for\",[30,0,[\"textareaId\"]]],[12],[1,\"\\n      \"],[1,[30,4]],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null],[13],[1,\"\\n\"]],[\"@label\",\"@name\",\"&attrs\",\"@message\"],false,[\"if\",\"efs-icon\"]]",
    "moduleName": "efs-components/components/ax-horizontal-textarea.hbs",
    "isStrictMode": false
  });

  class AxHorizontalTextareaComponent extends _axTextarea.default {
    get iconClassName() {
      const classNames = ["textarea-h__icon"];

      if (this.isError) {
        classNames.push("textarea-h--error__icon");
      } else if (this.isInvalid) {
        classNames.push("textarea-h--invalid__icon");
      }

      return classNames.join(" ");
    }

    get textareaClassName() {
      const classNames = ["textarea-h__textarea"];

      if (this.isError) {
        classNames.push("textarea-h--error__textarea");
      } else if (this.isInvalid) {
        classNames.push("textarea-h--invalid__textarea");
      }

      return classNames.join(" ");
    }

    get messageClassName() {
      const classNames = ["textarea-h__message"];

      if (this.isError) {
        classNames.push("textarea-h--error__message");
      } else if (this.isInvalid) {
        classNames.push("textarea-h--invalid__message");
      }

      return classNames.join(" ");
    }

    get wrapperClassName() {
      const classNames = ["textarea-h"];

      if (this.isError) {
        classNames.push("textarea-h--error");
      } else if (this.isInvalid) {
        classNames.push("textarea-h--invalid");
      }

      return classNames.join(" ");
    }

  }

  _exports.AxHorizontalTextareaComponent = AxHorizontalTextareaComponent;

  var _default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, AxHorizontalTextareaComponent);

  _exports.default = _default;
});