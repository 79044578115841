define("efs-components/templates/components/efs-link-button", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "S3CrnGNu",
    "block": "[[[10,0],[14,0,\"link-button__content\"],[15,\"data-test-link-button-content\",[30,0,[\"dataTestId\"]]],[12],[1,\"\\n\"],[41,[48,[30,1]],[[[1,\"    \"],[10,1],[14,0,\"link-button__text\"],[15,\"data-test-link-button-text\",[30,0,[\"dataTestId\"]]],[12],[1,\"\\n      \"],[18,1,null],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],[[[41,[30,0,[\"value\"]],[[[1,\"    \"],[10,1],[14,0,\"link-button__text\"],[15,\"data-test-link-button-text\",[30,0,[\"dataTestId\"]]],[12],[1,\"\\n      \"],[1,[30,0,[\"value\"]]],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[]],null]],[]]],[13]],[\"&default\"],false,[\"if\",\"has-block\",\"yield\"]]",
    "moduleName": "efs-components/templates/components/efs-link-button.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});