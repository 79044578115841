define("efs-components/components/efs-banner/index", ["exports", "@ember/component", "@ember/template-factory", "@ember/object", "@glimmer/component", "efs-components/utils/gen-uuid", "efs-components/utils/key-codes"], function (_exports, _component, _templateFactory, _object, _component2, _genUuid, _keyCodes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div
    class={{this.className}}
    aria-live="assertive"
    data-test-banner={{this.dataTestId}}
    name={{@name}}
    role="alert"
    tabindex="0"
    ...attributes
    {{on "keyup" this.keyupAction}}
    {{did-insert this.registerElement}}
  >
    <span class="banner__message" data-test-banner-message={{this.dataTestId}}>
      <span
        class="banner__icon"
        aria-hidden="true"
        data-test-banner-icon={{this.dataTestId}}
      >
        <EfsIcon @name={{this.iconName}} @id={{this.iconId}} />
      </span>
      <span class="banner__text" data-test-banner-text={{this.dataTestId}}>
        {{#if (has-block)}}
          {{yield this.linkClass}}
        {{else}}
          {{@message}}
        {{/if}}
      </span>
    </span>
    {{#if this.isClosable}}
      <button
        type="button"
        class={{this.closeClassName}}
        title="close this banner"
        aria-controls={{this.dataTestId}}
        data-test-banner-close={{this.dataTestId}}
        {{on "click" this.closeAction}}
      >
        <EfsIcon @name="close-big" @id={{concat this.dataTestId "-close-icon"}} />
      </button>
    {{/if}}
  </div>
  */
  {
    "id": "Ag7qTvMW",
    "block": "[[[11,0],[16,0,[30,0,[\"className\"]]],[24,\"aria-live\",\"assertive\"],[16,\"data-test-banner\",[30,0,[\"dataTestId\"]]],[16,3,[30,1]],[24,\"role\",\"alert\"],[24,\"tabindex\",\"0\"],[17,2],[4,[38,0],[\"keyup\",[30,0,[\"keyupAction\"]]],null],[4,[38,1],[[30,0,[\"registerElement\"]]],null],[12],[1,\"\\n  \"],[10,1],[14,0,\"banner__message\"],[15,\"data-test-banner-message\",[30,0,[\"dataTestId\"]]],[12],[1,\"\\n    \"],[10,1],[14,0,\"banner__icon\"],[14,\"aria-hidden\",\"true\"],[15,\"data-test-banner-icon\",[30,0,[\"dataTestId\"]]],[12],[1,\"\\n      \"],[8,[39,2],null,[[\"@name\",\"@id\"],[[30,0,[\"iconName\"]],[30,0,[\"iconId\"]]]],null],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,1],[14,0,\"banner__text\"],[15,\"data-test-banner-text\",[30,0,[\"dataTestId\"]]],[12],[1,\"\\n\"],[41,[48,[30,4]],[[[1,\"        \"],[18,4,[[30,0,[\"linkClass\"]]]],[1,\"\\n\"]],[]],[[[1,\"        \"],[1,[30,3]],[1,\"\\n\"]],[]]],[1,\"    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[41,[30,0,[\"isClosable\"]],[[[1,\"    \"],[11,\"button\"],[16,0,[30,0,[\"closeClassName\"]]],[24,\"title\",\"close this banner\"],[16,\"aria-controls\",[30,0,[\"dataTestId\"]]],[16,\"data-test-banner-close\",[30,0,[\"dataTestId\"]]],[24,4,\"button\"],[4,[38,0],[\"click\",[30,0,[\"closeAction\"]]],null],[12],[1,\"\\n      \"],[8,[39,2],null,[[\"@name\",\"@id\"],[\"close-big\",[28,[37,6],[[30,0,[\"dataTestId\"]],\"-close-icon\"],null]]],null],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null],[13]],[\"@name\",\"&attrs\",\"@message\",\"&default\"],false,[\"on\",\"did-insert\",\"efs-icon\",\"if\",\"has-block\",\"yield\",\"concat\"]]",
    "moduleName": "efs-components/components/efs-banner/index.hbs",
    "isStrictMode": false
  });

  let EfsBanner = (_class = class EfsBanner extends _component2.default {
    get bannerId() {
      if (this.modifier) {
        return `${this.modifier}-banner-${(0, _genUuid.default)(this)}`;
      }

      return `banner-${(0, _genUuid.default)(this)}`;
    }

    get className() {
      if (this.args.error) {
        return "banner banner--error";
      }

      if (this.args.warning) {
        return "banner banner--warning";
      }

      if (this.args.success) {
        return "banner banner--success";
      }

      return "banner";
    }

    get closeClassName() {
      if (this.modifier) {
        return `banner__close banner--${this.modifier}__close`;
      }

      return `banner__close`;
    }

    get dataTestId() {
      return this.args.name || this.bannerId;
    }

    get iconId() {
      if (this.args.error) {
        return `${this.dataTestId}-error-icon`;
      }

      if (this.args.success) {
        return `${this.dataTestId}-success-icon`;
      }

      if (this.args.warning) {
        return `${this.dataTestId}-warning-icon`;
      }

      return `${this.dataTestId}-info-icon`;
    }

    get iconName() {
      if (this.args.error) {
        return "alarm";
      }

      if (this.args.warning) {
        return "alarm-circular";
      }

      if (this.args.success) {
        return "checkmark-circular";
      }

      return "information";
    }

    get isClosable() {
      if ("closable" in this.args) {
        return this.args.closable;
      }

      return true;
    }

    get modifier() {
      if (this.args.success) {
        return "success";
      }

      if (this.args.warning) {
        return "warning";
      }

      if (this.args.error) {
        return "error";
      }

      return "";
    }

    get linkClass() {
      if (this.args.warning) {
        return "banner__link banner__link--warning";
      }

      return "banner__link";
    }

    closeAction() {
      if (this.args.onClose) {
        this.args.onClose();
      } else {
        this.element.remove();
      }
    }

    keyupAction(event) {
      let key = event.keyCode || event.which;

      if (_keyCodes.CLOSABLE_KEY_CODES.includes(key) && this.args.onClose) {
        this.args.onClose();
      }
    }

    registerElement(element) {
      this.element = element;
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "closeAction", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "closeAction"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "keyupAction", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "keyupAction"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "registerElement", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "registerElement"), _class.prototype)), _class);
  _exports.default = EfsBanner;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, EfsBanner);
});