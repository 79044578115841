define("efs-components/templates/components/efs-text-logo", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "UwkBXNk1",
    "block": "[[[8,[39,0],null,[[\"@dataTestId\"],[[30,0,[\"dataTestId\"]]]],null],[1,\"\\n\"],[10,1],[14,0,\"text-logo__product\"],[15,\"data-test-text-logo-product\",[30,0,[\"dataTestId\"]]],[12],[1,\"\\n  \"],[1,[30,0,[\"product\"]]],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[\"efs-logo-axcient\"]]",
    "moduleName": "efs-components/templates/components/efs-text-logo.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});