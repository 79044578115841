define("efs-components/components/efs-combo-select", ["exports", "@ember-decorators/component", "@ember/object", "@ember/object/computed", "@ember/component", "@ember/utils", "efs-components/utils/gen-uuid", "efs-components/templates/components/efs-combo-select"], function (_exports, _component, _object, _computed, _component2, _utils, _genUuid, _efsComboSelect) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _class, _class2, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const baseClassName = "combo-select";
  let EfsComboSelect = (_dec = (0, _component.layout)(_efsComboSelect.default), _dec2 = (0, _component.classNames)(baseClassName), _dec3 = (0, _component.attributeBindings)("dataTestId:data-test-combo-select", "name"), _dec4 = (0, _component.classNameBindings)(`invalid:${baseClassName}--invalid`, `error:${baseClassName}--error`, `isEmpty:${baseClassName}--empty`), _dec5 = (0, _computed.notEmpty)("iconName"), _dec6 = (0, _object.computed)("error", "invalid"), _dec7 = (0, _object.computed)("dataTestId", "error", "invalid"), _dec8 = (0, _object.computed)("error", "invalid"), _dec9 = (0, _object.computed)("error", "invalid"), _dec(_class = _dec2(_class = _dec3(_class = _dec4(_class = (_class2 = class EfsComboSelect extends _component2.default {
    constructor() {
      super(...arguments);

      // place simple properties and attributes in alphabetical order here:
      _defineProperty(this, "dataTestId", null);

      _defineProperty(this, "disabled", false);

      _defineProperty(this, "error", false);

      _defineProperty(this, "invalid", false);

      _defineProperty(this, "label", "");

      _defineProperty(this, "message", "");

      _defineProperty(this, "name", "");

      _defineProperty(this, "text", "");

      _defineProperty(this, "tooltip", "");

      _defineProperty(this, "noMatchesMessage", "No results found");

      _defineProperty(this, "options", null);

      _defineProperty(this, "placeholder", "Type your selection...");

      _defineProperty(this, "required", false);

      _defineProperty(this, "selected", null);

      _defineProperty(this, "tabindex", 0);

      _initializerDefineProperty(this, "iconVisible", _descriptor, this);
    }

    // place complex computed properties here:
    get comboSelectId() {
      return `combo-select-${(0, _genUuid.default)(this)}`;
    }

    get iconClassName() {
      if (this.error) {
        return "combo-select__icon combo-select--error__icon";
      }

      if (this.invalid) {
        return "combo-select__icon combo-select--invalid__icon";
      }

      return "combo-select__icon";
    }

    get iconId() {
      if (this.error) {
        return `${this.dataTestId}-error-icon`;
      }

      if (this.invalid) {
        return `${this.dataTestId}-invalid-icon`;
      }

      return "";
    }

    get iconName() {
      if (this.error) {
        return "alarm";
      }

      if (this.invalid) {
        return "alarm-circular";
      }

      return "";
    }

    get messageClassName() {
      if (this.error) {
        return "combo-select__message combo-select--error__message";
      }

      if (this.invalid) {
        return "combo-select__message combo-select--invalid__message";
      }

      return "combo-select__message";
    } // place methods and actions here:


    init() {
      super.init(...arguments);

      if (!this.dataTestId) {
        if (this.name) {
          this.set("dataTestId", this.name);
        } else {
          this.set("dataTestId", this.comboSelectId);
        }
      }

      if (!this.selected) {
        this.set("selected", []);
      }

      if (!this.options) {
        this.set("options", []);
      }
    }

    onChangeAction() {
      // Three arguments are: selected (an array), select (the ember-power-select object) and event
      this.onChange(...arguments);
    }

    onKeyDownAction() {
      // Two arguments are: select (the ember-power-select object) and event
      this.onKeyDown(...arguments);
    }

    onInputAction() {
      // Three arguments are: text, select (the ember-power-select object) and event
      this.onInput(...arguments);
    }

    onFocusAction() {
      // Two arguments are: select (the ember-power-select object) and event
      this.onFocus(...arguments);
    }

    onBlurAction() {
      // Two arguments are: select (the ember-power-select object) and event
      this.onBlur(...arguments);
    }

    createOnEnter(select, event) {
      if (event.code === "Enter" && select.isOpen && !select.highlighted && !(0, _utils.isBlank)(select.searchText)) {
        if (!this.selected.includes(select.searchText)) {
          this.options.unshift(select.searchText);
          select.actions.choose(select.searchText); // this invokes the onChange action
        }
      }
    }

    matcher(option, searchTerm) {
      if (option.label) {
        return option.label.toString().toLowerCase().includes(searchTerm.toLowerCase()) ? 1 : -1;
      }

      return option.toString().toLowerCase().includes(searchTerm.toLowerCase()) ? 1 : -1;
    }

    updateSelected(selectedAry) {
      this.set("selected", selectedAry);
    }

    typeAheadMatcher(option, searchTerm) {
      if (option.label) {
        return option.label.toLowerCase().startsWith(searchTerm.toLowerCase()) ? 1 : -1;
      }

      return option.toLowerCase().startsWith(searchTerm.toLowerCase()) ? 1 : -1;
    }

    onChange(selectedAry) {
      this.updateSelected(selectedAry);
    }

    onKeyDown(select, event) {
      this.createOnEnter(select, event);
    }

    onInput(text) {
      this.set("text", text);
    }

    onBlur() {}

    onFocus() {}

  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "iconVisible", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "iconClassName", [_dec6], Object.getOwnPropertyDescriptor(_class2.prototype, "iconClassName"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "iconId", [_dec7], Object.getOwnPropertyDescriptor(_class2.prototype, "iconId"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "iconName", [_dec8], Object.getOwnPropertyDescriptor(_class2.prototype, "iconName"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "messageClassName", [_dec9], Object.getOwnPropertyDescriptor(_class2.prototype, "messageClassName"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "onChangeAction", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "onChangeAction"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "onKeyDownAction", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "onKeyDownAction"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "onInputAction", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "onInputAction"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "onFocusAction", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "onFocusAction"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "onBlurAction", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "onBlurAction"), _class2.prototype)), _class2)) || _class) || _class) || _class) || _class);
  _exports.default = EfsComboSelect;
});