define("efs-components/components/efs-flag-message", ["exports", "@ember/component", "@ember/template-factory", "@ember/object", "@ember/service", "@glimmer/component", "efs-components/utils/gen-uuid"], function (_exports, _component, _templateFactory, _object, _service, _component2, _genUuid) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div
    class={{this.class}}
    data-test-flag-message={{this.dataTestId}}
    aria-labelledby={{this.flagId}}
    title={{this.message}}
    name={{this.name}}
    role={{this.role}}
    tabindex={{this.tabindex}}
    ...attributes
  >
    <div
      class="flag-message__status"
      data-test-flag-message-status={{this.dataTestId}}
    >
      <EfsIcon @name={{this.iconName}} @id={{this.iconId}} />
    </div>
  
    <div
      id={{this.flagId}}
      class="flag-message__text"
      data-test-flag-message-text={{this.dataTestId}}
    >
      {{#if (has-block)}}
        {{yield}}
      {{else}}
        {{this.message}}
      {{/if}}
    </div>
  
    <div
      class="flag-message__close-container"
      data-test-flag-message-close={{this.dataTestId}}
    >
      <button
        type="button"
        class="flag-message__close"
        aria-controls={{this.elementId}}
        title="close this flag message"
        data-test-flag-message-close-button={{this.dataTestId}}
        {{on "click" this.onClose}}
      >
        <EfsIcon
          @name="close-small"
          @id={{concat this.dataTestId "-close-icon"}}
        />
      </button>
    </div>
  </div>
  */
  {
    "id": "ThZpSn3Z",
    "block": "[[[11,0],[16,0,[30,0,[\"class\"]]],[16,\"data-test-flag-message\",[30,0,[\"dataTestId\"]]],[16,\"aria-labelledby\",[30,0,[\"flagId\"]]],[16,\"title\",[30,0,[\"message\"]]],[16,3,[30,0,[\"name\"]]],[16,\"role\",[30,0,[\"role\"]]],[16,\"tabindex\",[30,0,[\"tabindex\"]]],[17,1],[12],[1,\"\\n  \"],[10,0],[14,0,\"flag-message__status\"],[15,\"data-test-flag-message-status\",[30,0,[\"dataTestId\"]]],[12],[1,\"\\n    \"],[8,[39,0],null,[[\"@name\",\"@id\"],[[30,0,[\"iconName\"]],[30,0,[\"iconId\"]]]],null],[1,\"\\n  \"],[13],[1,\"\\n\\n  \"],[10,0],[15,1,[30,0,[\"flagId\"]]],[14,0,\"flag-message__text\"],[15,\"data-test-flag-message-text\",[30,0,[\"dataTestId\"]]],[12],[1,\"\\n\"],[41,[48,[30,2]],[[[1,\"      \"],[18,2,null],[1,\"\\n\"]],[]],[[[1,\"      \"],[1,[30,0,[\"message\"]]],[1,\"\\n\"]],[]]],[1,\"  \"],[13],[1,\"\\n\\n  \"],[10,0],[14,0,\"flag-message__close-container\"],[15,\"data-test-flag-message-close\",[30,0,[\"dataTestId\"]]],[12],[1,\"\\n    \"],[11,\"button\"],[24,0,\"flag-message__close\"],[16,\"aria-controls\",[30,0,[\"elementId\"]]],[24,\"title\",\"close this flag message\"],[16,\"data-test-flag-message-close-button\",[30,0,[\"dataTestId\"]]],[24,4,\"button\"],[4,[38,4],[\"click\",[30,0,[\"onClose\"]]],null],[12],[1,\"\\n      \"],[8,[39,0],null,[[\"@name\",\"@id\"],[\"close-small\",[28,[37,5],[[30,0,[\"dataTestId\"]],\"-close-icon\"],null]]],null],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"&attrs\",\"&default\"],false,[\"efs-icon\",\"if\",\"has-block\",\"yield\",\"on\",\"concat\"]]",
    "moduleName": "efs-components/components/efs-flag-message.hbs",
    "isStrictMode": false
  });

  let EfsFlagMessageComponent = (_class = class EfsFlagMessageComponent extends _component2.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "flagMessage", _descriptor, this);
    }

    get dataTestId() {
      return this.args.dataTestId || this.name || this.flagId;
    }

    get error() {
      return this.args.error || false;
    }

    get message() {
      return this.args.message || "";
    }

    get name() {
      return this.args.name || "";
    }

    get role() {
      return this.args.role || "alert";
    }

    get success() {
      return this.args.success || false;
    }

    get tabindex() {
      return this.args.tabindex || 0;
    }

    get warning() {
      return this.args.warning || false;
    }

    get flagId() {
      if (this.modifier) {
        return `flag-message-${this.modifier}-${(0, _genUuid.default)(this)}`;
      }

      return `flag-message-${(0, _genUuid.default)(this)}`;
    }

    get iconId() {
      if (this.error) {
        return `${this.dataTestId}-error-icon`;
      }

      if (this.success) {
        return `${this.dataTestId}-success-icon`;
      }

      if (this.warning) {
        return `${this.dataTestId}-warning-icon`;
      }

      return `${this.dataTestId}-info-icon`;
    }

    get iconName() {
      if (this.error) {
        return "alarm";
      }

      if (this.warning) {
        return "alarm-circular";
      }

      if (this.success) {
        return "checkmark-circular";
      }

      return "information";
    }

    get modifier() {
      if (this.success) {
        return "success";
      }

      if (this.warning) {
        return "warning";
      }

      if (this.error) {
        return "error";
      }

      return "";
    }

    get class() {
      const base = "flag-message";
      const status = this.modifier ? `${base}--${this.modifier}` : "";
      return [base, status].join(" ");
    }

    onClose() {
      if (this.args.onClose) {
        this.args.onClose();
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "flagMessage", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "onClose", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onClose"), _class.prototype)), _class);
  _exports.default = EfsFlagMessageComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, EfsFlagMessageComponent);
});