define("efs-components/components/efs-layout-panel-flag-message", ["exports", "@ember-decorators/component", "@ember/object", "@ember/service", "@ember/object/computed", "@ember/component", "efs-components/utils/gen-uuid", "efs-components/templates/components/efs-layout-panel-flag-message"], function (_exports, _component, _object, _service, _computed, _component2, _genUuid, _efsLayoutPanelFlagMessage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _class, _class2, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let EfsLayoutPanelFlagMessage = (_dec = (0, _component.layout)(_efsLayoutPanelFlagMessage.default), _dec2 = (0, _component.classNames)("layout-panel__flag-message"), _dec3 = (0, _component.classNameBindings)("active:layout-panel__flag-message--active"), _dec4 = (0, _component.attributeBindings)("dataTestId:data-test-layout-panel-flag-message"), _dec5 = (0, _computed.alias)("flagMessage.isActive"), _dec6 = (0, _computed.alias)("flagMessage.message"), _dec7 = (0, _computed.alias)("flagMessage.isSuccess"), _dec8 = (0, _computed.alias)("flagMessage.isWarning"), _dec9 = (0, _computed.alias)("flagMessage.isError"), _dec(_class = _dec2(_class = _dec3(_class = _dec4(_class = (_class2 = class EfsLayoutPanelFlagMessage extends _component2.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "flagMessage", _descriptor, this);

      // place simple properties and attributes in alphabetical order here:
      _defineProperty(this, "dataTestId", null);

      _defineProperty(this, "name", "");

      // place complex computed properties here:
      _initializerDefineProperty(this, "active", _descriptor2, this);

      _initializerDefineProperty(this, "message", _descriptor3, this);

      _initializerDefineProperty(this, "isSuccess", _descriptor4, this);

      _initializerDefineProperty(this, "isWarning", _descriptor5, this);

      _initializerDefineProperty(this, "isError", _descriptor6, this);
    }

    get panelId() {
      return `lp-flag-message-${(0, _genUuid.default)(this)}`;
    } // place methods and actions here:


    init() {
      super.init(...arguments);

      if (!this.dataTestId) {
        if (this.name) {
          this.set("dataTestId", this.name);
        } else {
          this.set("dataTestId", this.panelId);
        }
      }
    }

    closeFlag() {
      this.flagMessage.clear();
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "flagMessage", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "active", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class2.prototype, "message", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class2.prototype, "isSuccess", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class2.prototype, "isWarning", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class2.prototype, "isError", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "closeFlag", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "closeFlag"), _class2.prototype)), _class2)) || _class) || _class) || _class) || _class);
  _exports.default = EfsLayoutPanelFlagMessage;
});