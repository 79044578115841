define("efs-components/components/efs-modal-subtitle", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component"], function (_exports, _component, _templateFactory, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div
    class="text text--medium text--bold modal__subtitle"
    data-test-modal-subtitle={{this.dataTestId}}
    ...attributes
  >
    {{#if (has-block)}}
      {{yield}}
    {{else}}
      {{this.subtitle}}
    {{/if}}
  </div>
  */
  {
    "id": "WPRkdglS",
    "block": "[[[11,0],[24,0,\"text text--medium text--bold modal__subtitle\"],[16,\"data-test-modal-subtitle\",[30,0,[\"dataTestId\"]]],[17,1],[12],[1,\"\\n\"],[41,[48,[30,2]],[[[1,\"    \"],[18,2,null],[1,\"\\n\"]],[]],[[[1,\"    \"],[1,[30,0,[\"subtitle\"]]],[1,\"\\n\"]],[]]],[13]],[\"&attrs\",\"&default\"],false,[\"if\",\"has-block\",\"yield\"]]",
    "moduleName": "efs-components/components/efs-modal-subtitle.hbs",
    "isStrictMode": false
  });

  class EfsModalSubtitleComponent extends _component2.default {
    get dataTestId() {
      return this.args.dataTestId || "default-modal-subtitle";
    }

    get subtitle() {
      return this.args.subtitle || "";
    }

  }

  _exports.default = EfsModalSubtitleComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, EfsModalSubtitleComponent);
});