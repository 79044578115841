define("efs-components/services/banner", ["exports", "@ember/service", "@ember/array", "@ember/runloop"], function (_exports, _service, _array, _runloop) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class BannerService extends _service.default {
    constructor() {
      super(...arguments);

      _defineProperty(this, "queue", (0, _array.A)());
    }

    get isActive() {
      return this.queue.length > 0;
    }

    add(model) {
      if (model.message.length === 0) {
        throw new Error("Invalid announcement, message cannot be empty");
      }

      if (model.endDate) {
        let current = new Date().getTime();
        let endDate = model.endDate.getTime();
        let wait = endDate - current;
        (0, _runloop.later)(this, () => this.remove(model), wait);
      }

      this.queue.pushObject(model);
      return this;
    }

    remove(model) {
      this.queue.removeObject(model);
      return this;
    }

    clear() {
      this.queue.clear();
      return this;
    }

  }

  _exports.default = BannerService;
});