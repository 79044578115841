define("efs-components/components/efs-smart-search", ["exports", "@ember-decorators/component", "@ember/object", "@ember/component", "efs-components/utils/gen-uuid", "efs-components/utils/key-codes", "efs-components/templates/components/efs-smart-search"], function (_exports, _component, _object, _component2, _genUuid, _keyCodes, _efsSmartSearch) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _class, _class2;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  let EfsSmartSearch = (_dec = (0, _component.layout)(_efsSmartSearch.default), _dec2 = (0, _component.tagName)("span"), _dec3 = (0, _component.classNames)("smart-search"), _dec4 = (0, _component.classNameBindings)("isActive:smart-search--active"), _dec5 = (0, _component.attributeBindings)("dataTestId:data-test-smart-search", "name"), _dec6 = (0, _object.computed)("isActive"), _dec7 = (0, _object.computed)("dataTestId"), _dec(_class = _dec2(_class = _dec3(_class = _dec4(_class = _dec5(_class = (_class2 = class EfsSmartSearch extends _component2.default {
    constructor() {
      super(...arguments);

      // place simple properties and attributes in alphabetical order here:
      _defineProperty(this, "closable", true);

      _defineProperty(this, "dataTestId", null);

      _defineProperty(this, "isActive", false);

      _defineProperty(this, "name", "");

      _defineProperty(this, "placeholder", "Smart search...");

      _defineProperty(this, "tabindex", 0);

      _defineProperty(this, "title", "Smart Search");

      _defineProperty(this, "value", "");
    }

    onAdvancedSearch() {}

    onClick() {}

    onChange() {}

    onSimpleSearch() {} // place complex computed properties here:


    get buttonClassName() {
      const base = "smart-search__advanced-button";

      if (this.isActive) {
        return `${base} smart-search__advanced-button--active`;
      }

      return base;
    }

    get searchId() {
      return `search-${(0, _genUuid.default)(this)}`;
    }

    get searchPanelName() {
      return `${this.dataTestId}-advanced-search-panel`;
    } // place methods and actions here:


    init() {
      super.init(...arguments);

      if (!this.dataTestId) {
        if (this.name) {
          this.set("dataTestId", this.name);
        } else {
          this.set("dataTestId", this.searchId);
        }
      }
    }

    advancedSearch() {
      return this.onAdvancedSearch();
    }

    onClickAction() {
      return this.onClick(...arguments);
    }

    keyUpAction(event) {
      if ((event.keyCode || event.which) === _keyCodes.KEY_CODE_ENTER) {
        return this.onSimpleSearch(event.target.value);
      }
    }

    changeAction(event) {
      return this.onChange(event.target.value);
    }

    toggleSearchPanel() {
      return this.onToggleSearchPanel();
    }

    onToggleSearchPanel() {
      this.set("isActive", !this.isActive);
    }

  }, (_applyDecoratedDescriptor(_class2.prototype, "buttonClassName", [_dec6], Object.getOwnPropertyDescriptor(_class2.prototype, "buttonClassName"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "searchPanelName", [_dec7], Object.getOwnPropertyDescriptor(_class2.prototype, "searchPanelName"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "advancedSearch", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "advancedSearch"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "onClickAction", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "onClickAction"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "keyUpAction", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "keyUpAction"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "changeAction", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "changeAction"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "toggleSearchPanel", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "toggleSearchPanel"), _class2.prototype)), _class2)) || _class) || _class) || _class) || _class) || _class);
  _exports.default = EfsSmartSearch;
});