define("efs-components/templates/components/ax-breadcrumbs/home-page", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "9p2p8T6F",
    "block": "[[[41,[48,[30,1]],[[[1,\"  \"],[18,1,null],[1,\"\\n\"]],[]],[[[1,\"  \"],[8,[39,3],[[24,\"data-test-breadcrumbs-home-link\",\"default-home\"]],[[\"@route\"],[\"index\"]],[[\"default\"],[[[[1,\"\\n    \"],[8,[39,4],null,[[\"@name\",\"@class\",\"@id\"],[[30,0,[\"icon\"]],\"breadcrumbs__home-icon\",[28,[37,5],[[30,0,[\"name\"]],\"-home-icon\"],null]]],null],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"]],[]]]],[\"&default\"],false,[\"if\",\"has-block\",\"yield\",\"link-to\",\"efs-icon\",\"concat\"]]",
    "moduleName": "efs-components/templates/components/ax-breadcrumbs/home-page.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});