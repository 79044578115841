define("efs-components/components/efs-progress-circle", ["exports", "@ember-decorators/component", "@ember/object", "@ember/component", "efs-components/utils/gen-uuid", "efs-components/templates/components/efs-progress-circle"], function (_exports, _component, _object, _component2, _genUuid, _efsProgressCircle) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _class, _class2;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  // Component that displays a progress circle.
  // Progress circle is built using SVG (Scalable Vector Graphics).
  // Details on SVG: https://www.w3.org/Graphics/SVG/
  // Details on SVG API: https://developer.mozilla.org/en-US/docs/Web/SVG
  // Details on SVG progress ring: https://css-tricks.com/building-progress-ring-quickly/
  // Details on SVG scaling: https://css-tricks.com/scale-svg/
  let EfsProgressCircle = (_dec = (0, _component.layout)(_efsProgressCircle.default), _dec2 = (0, _component.tagName)("figure"), _dec3 = (0, _component.classNames)("progress-circle"), _dec4 = (0, _component.attributeBindings)("dataTestId:data-test-progress-circle", "name"), _dec5 = (0, _object.computed)("radius"), _dec6 = (0, _object.computed)("percentage"), _dec7 = (0, _object.computed)("percentage", "hasValidPercentage", "zeroPercentage"), _dec8 = (0, _object.computed)("XYcoordinates", "strokeWidth"), _dec9 = (0, _object.computed)("circumference", "hasValidPercentage", "percentage", "radius"), _dec10 = (0, _object.computed)("percentage"), _dec(_class = _dec2(_class = _dec3(_class = _dec4(_class = (_class2 = class EfsProgressCircle extends _component2.default {
    constructor() {
      super(...arguments);

      // place simple properties and attributes in alphabetical order here:
      _defineProperty(this, "dataTestId", null);

      _defineProperty(this, "name", "");

      _defineProperty(this, "percentage", null);

      _defineProperty(this, "size", 90);

      _defineProperty(this, "strokeWidth", 10);

      _defineProperty(this, "useCaption", true);

      _defineProperty(this, "XYcoordinates", 50);

      _defineProperty(this, "viewBoxSize", 100);
    }

    // place complex computed properties here:
    // Circumferance of the circle L = 2*PI*R
    get circumference() {
      return parseInt(Math.PI * (2 * this.radius), 10);
    } // Valid are all decimals and integers between 0 and 100


    get hasValidPercentage() {
      let regex = /(?:\b|-)([0-9]{1,2}[0]?|100)\b/;
      return regex.test(this.percentage);
    }

    get indicatorClassName() {
      let state = "";
      const base = "progress-circle__indicator";

      if (this.percentage === null) {
        return base;
      }

      if (!this.hasValidPercentage) {
        state = `${base}--invalid`;
      } else if (this.zeroPercentage) {
        state = `${base}--empty`;
      } else if (parseInt(this.percentage, 10) === 100) {
        state = `${base}--completed`;
      }

      return [base, state].join(" ");
    }

    get progressCircleId() {
      return `progress-circle-${(0, _genUuid.default)(this)}`;
    } // Subtract the borderwidth from the radius, so the svg stays in the viewbox


    get radius() {
      return this.XYcoordinates - parseInt(this.strokeWidth, 10);
    } // This calculates the progress: offset = L - (percent/100)*L


    get strokeDashoffset() {
      let circumference = this.circumference;
      let percentage = this.hasValidPercentage ? this.percentage : 0;
      return Math.floor(circumference - percentage / 100 * circumference);
    } // computed in this way to normalize percentage 0 integer vs string


    get zeroPercentage() {
      return parseInt(this.percentage, 10) === 0;
    } // place methods and actions here:


    init() {
      super.init(...arguments);

      if (!this.dataTestId) {
        if (this.name) {
          this.set("dataTestId", this.name);
        } else {
          this.set("dataTestId", this.progressCircleId);
        }
      }
    }

  }, (_applyDecoratedDescriptor(_class2.prototype, "circumference", [_dec5], Object.getOwnPropertyDescriptor(_class2.prototype, "circumference"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "hasValidPercentage", [_dec6], Object.getOwnPropertyDescriptor(_class2.prototype, "hasValidPercentage"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "indicatorClassName", [_dec7], Object.getOwnPropertyDescriptor(_class2.prototype, "indicatorClassName"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "radius", [_dec8], Object.getOwnPropertyDescriptor(_class2.prototype, "radius"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "strokeDashoffset", [_dec9], Object.getOwnPropertyDescriptor(_class2.prototype, "strokeDashoffset"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "zeroPercentage", [_dec10], Object.getOwnPropertyDescriptor(_class2.prototype, "zeroPercentage"), _class2.prototype)), _class2)) || _class) || _class) || _class) || _class);
  _exports.default = EfsProgressCircle;
});