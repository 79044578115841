define("efs-components/templates/components/efs-main-menu-link", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "aUSjCtEH",
    "block": "[[[8,[39,0],[[24,0,\"main-menu-link\"],[16,\"data-test-main-menu-link-a\",[30,0,[\"dataTestId\"]]]],[[\"@route\",\"@activeClass\"],[[30,0,[\"route\"]],\"main-menu-link--active\"]],[[\"default\"],[[[[1,\"\\n  \"],[8,[39,1],null,[[\"@name\",\"@class\",\"@id\"],[[30,0,[\"icon\"]],\"main-menu-link__icon\",[28,[37,2],[[30,0,[\"dataTestId\"]],\"-icon\"],null]]],null],[1,\"\\n  \"],[10,1],[14,0,\"main-menu-link__text\"],[15,\"data-test-main-menu-link-text\",[30,0,[\"dataTestId\"]]],[12],[1,\"\\n\"],[41,[48,[30,1]],[[[1,\"      \"],[18,1,null],[1,\"\\n\"]],[]],[[[1,\"      \"],[1,[30,0,[\"label\"]]],[1,\"\\n\"]],[]]],[1,\"  \"],[13],[1,\"\\n\"]],[]]]]]],[\"&default\"],false,[\"link-to\",\"efs-icon\",\"concat\",\"if\",\"has-block\",\"yield\"]]",
    "moduleName": "efs-components/templates/components/efs-main-menu-link.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});