define("efs-components/utils/string", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.hasRepeatingCharacters = hasRepeatingCharacters;
  _exports.isAlphaNumericPlusAddressCharacters = isAlphaNumericPlusAddressCharacters;
  _exports.isMultiLineAlphaNumericPlus = isMultiLineAlphaNumericPlus;
  _exports.isOnlyAlpha = isOnlyAlpha;
  _exports.isOnlyAlphaNumeric = isOnlyAlphaNumeric;
  _exports.isOnlyAlphaNumericWithPuncutation = isOnlyAlphaNumericWithPuncutation;
  _exports.isOnlyAlphaWithPuncutation = isOnlyAlphaWithPuncutation;
  _exports.isOnlyNumbersAndSeperatorCharacters = isOnlyNumbersAndSeperatorCharacters;
  _exports.isOnlyNumeric = isOnlyNumeric;
  _exports.isValidInternationalPhone = isValidInternationalPhone;
  _exports.isValidInternationalPhoneLimit15 = isValidInternationalPhoneLimit15;
  _exports.isValidName = isValidName;
  _exports.isValidPostalCodeCharacters = isValidPostalCodeCharacters;
  _exports.isValidURI = isValidURI;
  _exports.renderMultiline = renderMultiline;
  _exports.truncate = truncate;

  ///////////////////////////////////////////
  ////// Methods for MUTATING strings: //////
  ///////////////////////////////////////////
  function renderMultiline(str) {
    return (str || "").replace(/[\r\n]/g, "<br>");
  }

  function truncate(str) {
    let maxLength = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 100;

    if (str) {
      return str.length > maxLength ? `${str.slice(0, Math.max(0, maxLength))}...` : str;
    }

    return "";
  } //////////////////////////////////////////
  ////// Methods for TESTING strings: //////
  //////////////////////////////////////////


  function hasRepeatingCharacters(str) {
    let regex = new RegExp(`(.)\\1{2,}`, "gi");
    return regex.test(str);
  }

  function isOnlyAlpha(str) {
    return !/[^a-z]/gi.test(str);
  }

  function isOnlyAlphaNumeric(str) {
    return !/[^a-z0-9]/gi.test(str);
  }

  function isOnlyAlphaWithPuncutation(str) {
    return !/[^a-z ,.'-]/gi.test(str);
  }

  function isOnlyAlphaNumericWithPuncutation(str) {
    return !/[^a-z0-9 ,.'-]/gi.test(str);
  }

  function isOnlyNumeric(str) {
    return !/[^0-9]/gi.test(str);
  } ////////////////////////////////////////////////////////////////////
  ////// Methods for parsing International Names and Addresses: //////
  ////////////////////////////////////////////////////////////////////
  // Following is for parsing international addresses
  // it contains common address punctuation: @ & : # + ' - \ / . ,
  // as well as international alphabet characters
  // good for use with Street Addresses and City names
  // this is NOT pattern matching, only character validation
  // test it out https://regexr.com/42pfc


  function isAlphaNumericPlusAddressCharacters(str) {
    /* eslint-disable-next-line no-misleading-character-class */
    return !/[^@&:#+'\- /\\.,a-z0-9ÆÐƎƏƐƔĲŊŒẞÞǷȜæðǝəɛɣĳŋœĸſßþƿȝĄƁÇĐƊĘĦĮƘŁØƠŞȘŢȚŦŲƯY̨Ƴąɓçđɗęħįƙłøơşșţțŧųưy̨ƴÁÀÂÄǍĂĀÃÅǺĄÆǼǢƁĆĊĈČÇĎḌĐƊÐÉÈĖÊËĚĔĒĘẸƎƏƐĠĜǦĞĢƔáàâäǎăāãåǻąæǽǣɓćċĉčçďḍđɗðéèėêëěĕēęẹǝəɛġĝǧğģɣĤḤĦIÍÌİÎÏǏĬĪĨĮỊĲĴĶƘĹĻŁĽĿʼNŃN̈ŇÑŅŊÓÒÔÖǑŎŌÕŐỌØǾƠŒĥḥħıíìiîïǐĭīĩįịĳĵķƙĸĺļłľŀŉńn̈ňñņŋóòôöǒŏōõőọøǿơœŔŘŖŚŜŠŞȘṢẞŤŢṬŦÞÚÙÛÜǓŬŪŨŰŮŲỤƯẂẀŴẄǷÝỲŶŸȲỸƳŹŻŽẒŕřŗſśŝšşșṣßťţṭŧþúùûüǔŭūũűůųụưẃẁŵẅƿýỳŷÿȳỹƴźżžẓ]/gi.test(str);
  } // please see isAlphaNumericPlusAddressCharacters function above
  // this is that function with the addition of `/\s`
  // to allow whitespace characters


  function isMultiLineAlphaNumericPlus(str) {
    /* eslint-disable-next-line no-misleading-character-class */
    return !/[^@&:#+'\- /\s/\\.,a-z0-9ÆÐƎƏƐƔĲŊŒẞÞǷȜæðǝəɛɣĳŋœĸſßþƿȝĄƁÇĐƊĘĦĮƘŁØƠŞȘŢȚŦŲƯY̨Ƴąɓçđɗęħįƙłøơşșţțŧųưy̨ƴÁÀÂÄǍĂĀÃÅǺĄÆǼǢƁĆĊĈČÇĎḌĐƊÐÉÈĖÊËĚĔĒĘẸƎƏƐĠĜǦĞĢƔáàâäǎăāãåǻąæǽǣɓćċĉčçďḍđɗðéèėêëěĕēęẹǝəɛġĝǧğģɣĤḤĦIÍÌİÎÏǏĬĪĨĮỊĲĴĶƘĹĻŁĽĿʼNŃN̈ŇÑŅŊÓÒÔÖǑŎŌÕŐỌØǾƠŒĥḥħıíìiîïǐĭīĩįịĳĵķƙĸĺļłľŀŉńn̈ňñņŋóòôöǒŏōõőọøǿơœŔŘŖŚŜŠŞȘṢẞŤŢṬŦÞÚÙÛÜǓŬŪŨŰŮŲỤƯẂẀŴẄǷÝỲŶŸȲỸƳŹŻŽẒŕřŗſśŝšşșṣßťţṭŧþúùûüǔŭūũűůųụưẃẁŵẅƿýỳŷÿȳỹƴźżžẓ]/gi.test(str);
  } // Intrnational Human names
  // this is NOT pattern matching, only character validation
  // https://regexr.com/42raq


  function isValidName(str) {
    return /^['\- a-zÅåÄäáàãâÖöóõðØøÆæÉéÈèëÜüÊêÛúûÎîíïçñšýž]*$/gi.test(str);
  } // this is NOT pattern matching, only character validation


  function isValidPostalCodeCharacters(str) {
    return !/[^a-z0-9\- ]/gi.test(str);
  } ///////////////////////////////////////////////////////
  ////// Methods for parsing PHONE NUMBER strings: //////
  ///////////////////////////////////////////////////////
  // this is NOT pattern matching, only character validation


  function isOnlyNumbersAndSeperatorCharacters(str) {
    return !/[^+()\- /~.\\0-9]/gi.test(str);
  } // test this out https://regexr.com/42r86
  // this validates the most valid international Phone examples with more false positives
  // this can accomodate extensions listed at the end
  // good for traditional PHONE and FAX
  // valid characters include: - / ~ . \ ( )


  function isValidInternationalPhone(str) {
    return /^[+]?([( ]?[0-9]{1,4}[)]?)(( ?[- /~.\\]?[( ]?[0-9]{1,3}[)]?){1,4})([, ]?[- /~.\\]?[( ]?x?(ex)?(ext)?[.:]? ?[0-9]{2,7}[)]?)$/gi.test(str);
  } // test this out https://regexr.com/42pd1
  // this validates slightly fewer valid international Phone exapmles with fewer false positives
  // this does not play well with extensions `x1234`
  // good for MOBILE PHONE validation


  function isValidInternationalPhoneLimit15(str) {
    return /^[+]?([( ]?[0-9][) ]?){1,4}([+()\- /~.\\]?[0-9]){4,11}$/gi.test(str);
  } /////////////////////////////////////////////////
  ////// Method for parsing WEBSITE strings: //////
  /////////////////////////////////////////////////
  // Reasonable URI characters that can include sorts and searches:
  // Special characters allowed: / : . ? = & _ ~ - % @ #
  // this is NOT pattern matching, only character validation
  // https://regexr.com/42un0


  function isValidURI(str) {
    return !/[^a-z0-9/:.?=&_~\-%@#]/gi.test(str);
  }
});