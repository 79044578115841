define("efs-components/templates/components/efs-table-body-more-action", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "2oTiJloB",
    "block": "[[[11,\"button\"],[24,0,\"text table__body-more-action\"],[16,\"disabled\",[30,0,[\"disabled\"]]],[16,\"data-test-table-body-more-action\",[30,0,[\"dataTestId\"]]],[24,4,\"button\"],[4,[38,0],[\"click\",[28,[37,1],[[30,0],\"clickAction\"],null]],null],[12],[1,\"\\n  \"],[1,[30,0,[\"label\"]]],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[\"on\",\"action\"]]",
    "moduleName": "efs-components/templates/components/efs-table-body-more-action.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});