define("efs-components/templates/components/ax-breadcrumbs/current-page", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "Ae7SoRVi",
    "block": "[[[10,1],[14,0,\"breadcrumbs__separator\"],[15,\"data-test-breadcrumbs-separator\",[30,0,[\"name\"]]],[12],[1,\"\\n  /\\n\"],[13],[1,\"\\n\"],[10,1],[14,0,\"breadcrumbs__text breadcrumbs__text--current\"],[15,\"data-test-breadcrumbs-label\",[30,0,[\"name\"]]],[12],[1,\"\\n  \"],[1,[30,0,[\"label\"]]],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[]]",
    "moduleName": "efs-components/templates/components/ax-breadcrumbs/current-page.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});