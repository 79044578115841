define("efs-components/templates/components/efs-device-status", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "GD6FuTsU",
    "block": "[[[10,0],[14,0,\"device-status__header\"],[15,\"data-test-device-status-header\",[30,0,[\"dataTestId\"]]],[12],[1,\"\\n  \"],[8,[39,0],null,[[\"@class\",\"@id\",\"@name\"],[\"device-status__icon\",[28,[37,1],[[30,0,[\"dataTestId\"]],\"-status-icon\"],null],[30,0,[\"iconName\"]]]],null],[1,\"\\n\\n  \"],[10,1],[14,0,\"device-status__status-text\"],[15,\"data-test-device-status-status-text\",[30,0,[\"dataTestId\"]]],[12],[1,\"\\n    \"],[1,[30,0,[\"statusText\"]]],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[10,0],[14,0,\"device-status__content\"],[15,\"data-test-device-status-content\",[30,0,[\"dataTestId\"]]],[12],[1,\"\\n\"],[41,[30,0,[\"imageSrc\"]],[[[1,\"    \"],[10,\"img\"],[14,0,\"device-status__image\"],[15,\"data-test-device-status-image\",[30,0,[\"dataTestId\"]]],[15,\"src\",[30,0,[\"imageSrc\"]]],[15,\"alt\",[30,0,[\"imageAlt\"]]],[12],[13],[1,\"\\n\"]],[]],[[[41,[30,0,[\"logoIconName\"]],[[[1,\"    \"],[8,[39,0],null,[[\"@class\",\"@id\",\"@name\"],[\"device-status__logo-icon\",[28,[37,1],[[30,0,[\"dataTestId\"]],\"-logo-icon\"],null],[30,0,[\"logoIconName\"]]]],null],[1,\"\\n  \"]],[]],null]],[]]],[13],[1,\"\\n\\n\"],[10,0],[14,0,\"device-status__footer\"],[12],[1,\"\\n  \"],[8,[39,0],null,[[\"@class\",\"@id\",\"@name\"],[\"device-status__item-icon\",[28,[37,1],[[30,0,[\"dataTestId\"]],\"-item-icon\"],null],[30,0,[\"itemIconName\"]]]],null],[1,\"\\n\\n  \"],[10,1],[14,0,\"device-status__item-name\"],[15,\"data-test-device-status-item-name\",[30,0,[\"dataTestId\"]]],[15,\"title\",[30,0,[\"itemName\"]]],[12],[1,\"\\n    \"],[1,[30,0,[\"itemName\"]]],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[\"efs-icon\",\"concat\",\"if\"]]",
    "moduleName": "efs-components/templates/components/efs-device-status.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});