define("efs-components/templates/components/efs-checkbox", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "l9lFtXWF",
    "block": "[[[10,1],[14,0,\"text checkbox__label-text\"],[15,\"data-test-checkbox-label\",[30,0,[\"dataTestId\"]]],[12],[1,\"\\n\"],[41,[48,[30,2]],[[[1,\"    \"],[18,2,null],[1,\"\\n\"]],[]],[[[1,\"    \"],[1,[30,0,[\"label\"]]],[1,\"\\n\"]],[]]],[13],[1,\"\\n\\n\"],[11,\"input\"],[16,\"checked\",[30,0,[\"checked\"]]],[24,0,\"checkbox__input\"],[16,\"data-test-checkbox-input\",[30,0,[\"dataTestId\"]]],[16,\"disabled\",[30,0,[\"disabled\"]]],[16,1,[30,0,[\"checkId\"]]],[16,3,[30,0,[\"name\"]]],[24,\"role\",\"checkbox\"],[24,4,\"checkbox\"],[17,1],[4,[38,3],[\"click\",[30,0,[\"checkboxToggled\"]]],null],[12],[13],[1,\"\\n\\n\"],[10,0],[15,0,[30,0,[\"indicatorClassName\"]]],[15,\"data-test-checkbox-indicator\",[30,0,[\"dataTestId\"]]],[12],[13]],[\"&attrs\",\"&default\"],false,[\"if\",\"has-block\",\"yield\",\"on\"]]",
    "moduleName": "efs-components/templates/components/efs-checkbox.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});