define("efs-components/templates/components/efs-toggle", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "fPTFgjpw",
    "block": "[[[11,\"input\"],[16,\"checked\",[30,0,[\"checked\"]]],[24,0,\"toggle__check toggle__check--visually-hidden\"],[16,\"data-test-toggle-input\",[30,0,[\"dataTestId\"]]],[16,\"disabled\",[30,0,[\"disabled\"]]],[16,1,[30,0,[\"toggleId\"]]],[16,3,[30,0,[\"name\"]]],[24,\"role\",\"checkbox\"],[16,\"tabindex\",[30,0,[\"tabindex\"]]],[24,4,\"checkbox\"],[17,1],[4,[38,0],[\"change\",[28,[37,1],[[30,0],\"toggleChecked\"],null]],null],[12],[13],[1,\"\\n\"],[10,0],[14,0,\"toggle__slider\"],[15,\"data-test-toggle-slider\",[30,0,[\"dataTestId\"]]],[12],[13],[1,\"\\n\"]],[\"&attrs\"],false,[\"on\",\"action\"]]",
    "moduleName": "efs-components/templates/components/efs-toggle.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});