define("efs-components/templates/components/efs-table-header-row", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "Rirmmtij",
    "block": "[[[41,[48,[30,1]],[[[1,\"  \"],[18,1,[[30,0,[\"dataTestId\"]]]],[1,\"\\n\"]],[]],null]],[\"&default\"],false,[\"if\",\"has-block\",\"yield\"]]",
    "moduleName": "efs-components/templates/components/efs-table-header-row.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});