define("efs-components/templates/components/efs-spinner", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "3B8aAvR2",
    "block": "[[[10,0],[14,0,\"spinner1 spinner-child\"],[12],[13],[1,\"\\n\"],[10,0],[14,0,\"spinner2 spinner-child\"],[12],[13],[1,\"\\n\"],[10,0],[14,0,\"spinner3 spinner-child\"],[12],[13],[1,\"\\n\"],[10,0],[14,0,\"spinner4 spinner-child\"],[12],[13],[1,\"\\n\"],[10,0],[14,0,\"spinner5 spinner-child\"],[12],[13],[1,\"\\n\"],[10,0],[14,0,\"spinner6 spinner-child\"],[12],[13],[1,\"\\n\"],[10,0],[14,0,\"spinner7 spinner-child\"],[12],[13],[1,\"\\n\"],[10,0],[14,0,\"spinner8 spinner-child\"],[12],[13],[1,\"\\n\"],[10,0],[14,0,\"spinner9 spinner-child\"],[12],[13],[1,\"\\n\"],[10,0],[14,0,\"spinner10 spinner-child\"],[12],[13],[1,\"\\n\"],[10,0],[14,0,\"spinner11 spinner-child\"],[12],[13],[1,\"\\n\"],[10,0],[14,0,\"spinner12 spinner-child\"],[12],[13],[1,\"\\n\"]],[],false,[]]",
    "moduleName": "efs-components/templates/components/efs-spinner.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});