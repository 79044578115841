define("efs-components/utils/number", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.precisionRound = precisionRound;

  function precisionRound(num) {
    let precision = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
    let factor = Math.pow(10, precision);
    return Math.round(num * factor) / factor;
  }
});