define("ember-metrics/metrics-adapters/pendo", ["exports", "ember-metrics/metrics-adapters/base", "@ember/debug", "ember-metrics/-private/utils/object-transforms", "ember-metrics/-private/utils/remove-from-dom"], function (_exports, _base, _debug, _objectTransforms, _removeFromDom) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class Pendo extends _base.default {
    toStringExtension() {
      return 'Pendo';
    } // eslint-disable-next-line ember/classic-decorator-hooks


    init() {
      const {
        apiKey
      } = this.config;
      (false && !(apiKey) && (0, _debug.assert)(`[ember-metrics] You must pass a valid \`apiKey\` to the ${this.toString()} adapter`, apiKey));

      this._injectScript(apiKey);

      window.pendo.initialize(this.config);
    }
    /* eslint-disable */
    // prettier-ignore


    _injectScript(apiKey) {
      (function (p, e, n, d, o) {
        var v, w, x, y, z;
        o = p[d] = p[d] || {};
        o._q = [];
        v = ['initialize', 'identify', 'updateOptions', 'pageLoad', 'track'];

        for (w = 0, x = v.length; w < x; ++w) (function (m) {
          o[m] = o[m] || function () {
            o._q[m === v[0] ? 'unshift' : 'push']([m].concat([].slice.call(arguments, 0)));
          };
        })(v[w]);

        y = e.createElement(n);
        y.async = !0;
        y.src = `https://cdn.pendo.io/agent/static/${apiKey}/pendo.js`;
        z = e.getElementsByTagName(n)[0];
        z.parentNode.insertBefore(y, z);
      })(window, document, 'script', 'pendo');
    }
    /* eslint-enable */


    identify() {
      let options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      const compactedOptions = (0, _objectTransforms.compact)(options);
      window.pendo.identify(compactedOptions);
    }

    trackEvent() {
      let options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      const compactedOptions = (0, _objectTransforms.compact)(options);
      const {
        event
      } = compactedOptions;
      const props = (0, _objectTransforms.without)(compactedOptions, 'event');

      if ((0, _objectTransforms.hasOwnProperties)(props)) {
        window.pendo.track(event, props);
      } else {
        window.pendo.track(event);
      }
    }

    trackPage() {
      let options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      const event = {
        event: 'page viewed'
      };
      const mergedOptions = { ...event,
        ...options
      };
      this.trackEvent(mergedOptions);
    }

    willDestroy() {
      (0, _removeFromDom.default)('script[src*="pendo.js"]');
      delete window.pendo;
    }

  }

  _exports.default = Pendo;
});