define("efs-components/components/efs-date-picker", ["exports", "@ember-decorators/component", "@ember/object", "@ember/object/computed", "@ember/component", "efs-components/utils/gen-uuid", "efs-components/templates/components/efs-date-picker"], function (_exports, _component, _object, _computed, _component2, _genUuid, _efsDatePicker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _class, _class2, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let EfsDatePicker = (_dec = (0, _component.layout)(_efsDatePicker.default), _dec2 = (0, _component.classNames)("date-picker"), _dec3 = (0, _component.classNameBindings)("error:date-picker--error", "invalid:date-picker--invalid"), _dec4 = (0, _component.attributeBindings)("dataTestId:data-test-date-picker", "name"), _dec5 = (0, _computed.notEmpty)("iconName"), _dec6 = (0, _object.computed)("error", "invalid"), _dec7 = (0, _object.computed)("dataTestId", "error", "invalid"), _dec8 = (0, _object.computed)("error", "invalid"), _dec9 = (0, _object.computed)("error", "invalid"), _dec10 = (0, _object.computed)("error", "invalid"), _dec(_class = _dec2(_class = _dec3(_class = _dec4(_class = (_class2 = class EfsDatePicker extends _component2.default {
    constructor() {
      super(...arguments);

      // place simple properties and attributes in alphabetical order here:
      _defineProperty(this, "autocomplete", "");

      _defineProperty(this, "autofocus", false);

      _defineProperty(this, "dataTestId", null);

      _defineProperty(this, "disabled", false);

      _defineProperty(this, "error", false);

      _defineProperty(this, "invalid", false);

      _defineProperty(this, "label", "");

      _defineProperty(this, "max", "");

      _defineProperty(this, "message", "");

      _defineProperty(this, "min", "");

      _defineProperty(this, "name", "");

      _defineProperty(this, "placeholder", "YYYY/MM/DD");

      _defineProperty(this, "readOnly", false);

      _defineProperty(this, "required", false);

      _defineProperty(this, "step", 1);

      _defineProperty(this, "tabindex", 0);

      _defineProperty(this, "value", "");

      _initializerDefineProperty(this, "iconVisible", _descriptor, this);
    }

    onChange() {}

    onKeyUp() {}

    // place complex computed properties here:
    get iconClassName() {
      if (this.error) {
        return "date-picker__icon date-picker--error__icon";
      }

      if (this.invalid) {
        return "date-picker__icon date-picker--invalid__icon";
      }

      return "date-picker__icon";
    }

    get iconId() {
      if (this.error) {
        return `${this.dataTestId}-error-icon`;
      }

      if (this.invalid) {
        return `${this.dataTestId}-invalid-icon`;
      }

      return "";
    }

    get iconName() {
      if (this.error) {
        return "alarm";
      }

      if (this.invalid) {
        return "alarm-circular";
      }

      return "";
    }

    get inputBoxClassName() {
      if (this.error) {
        return "date-picker__input-box date-picker--error__input-box";
      }

      if (this.invalid) {
        return "date-picker__input-box date-picker--invalid__input-box";
      }

      return "date-picker__input-box";
    }

    get dpId() {
      return `date-picker-${(0, _genUuid.default)(this)}`;
    }

    get messageClassName() {
      if (this.error) {
        return "date-picker__message date-picker--error__message";
      }

      if (this.invalid) {
        return "date-picker__message date-picker--invalid__message";
      }

      return "date-picker__message";
    } // place methods and actions here:


    init() {
      super.init(...arguments);

      if (!this.dataTestId) {
        if (this.name) {
          this.set("dataTestId", this.name);
        } else {
          this.set("dataTestId", this.dpId);
        }
      }
    }

    inputChanged(event) {
      this.onChange(event.target.value);
    }

    inputKeyUp(event) {
      this.onKeyUp(event.target.value);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "iconVisible", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "iconClassName", [_dec6], Object.getOwnPropertyDescriptor(_class2.prototype, "iconClassName"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "iconId", [_dec7], Object.getOwnPropertyDescriptor(_class2.prototype, "iconId"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "iconName", [_dec8], Object.getOwnPropertyDescriptor(_class2.prototype, "iconName"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "inputBoxClassName", [_dec9], Object.getOwnPropertyDescriptor(_class2.prototype, "inputBoxClassName"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "messageClassName", [_dec10], Object.getOwnPropertyDescriptor(_class2.prototype, "messageClassName"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "inputChanged", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "inputChanged"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "inputKeyUp", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "inputKeyUp"), _class2.prototype)), _class2)) || _class) || _class) || _class) || _class);
  _exports.default = EfsDatePicker;
});