define("efs-components/components/efs-search-panel-footer", ["exports", "@ember-decorators/component", "@ember/component", "efs-components/templates/components/efs-search-panel-footer"], function (_exports, _component, _component2, _efsSearchPanelFooter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  let EfsSearchPanelFooter = (_dec = (0, _component.layout)(_efsSearchPanelFooter.default), _dec2 = (0, _component.classNames)("search-panel-footer"), _dec3 = (0, _component.attributeBindings)("dataTestId:data-test-search-panel-footer"), _dec(_class = _dec2(_class = _dec3(_class = class EfsSearchPanelFooter extends _component2.default {
    constructor() {
      super(...arguments);

      _defineProperty(this, "dataTestId", "default-search-panel-footer");
    }

  }) || _class) || _class) || _class);
  _exports.default = EfsSearchPanelFooter;
});