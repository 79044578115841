define("efs-components/components/efs-multi-select", ["exports", "@ember-decorators/component", "@ember/object", "@ember/object/computed", "@ember/component", "efs-components/templates/components/efs-multi-select", "efs-components/utils/gen-uuid"], function (_exports, _component, _object, _computed, _component2, _efsMultiSelect, _genUuid) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _class, _class2, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const baseClassName = "multi-select";
  let EfsMultiSelect = (_dec = (0, _component.layout)(_efsMultiSelect.default), _dec2 = (0, _component.classNames)(baseClassName), _dec3 = (0, _component.attributeBindings)("dataTestId:data-test-multi-select", "name"), _dec4 = (0, _component.classNameBindings)(`isEmpty:${baseClassName}--empty`, `required:${baseClassName}--required`), _dec5 = (0, _computed.empty)("selected"), _dec6 = (0, _object.computed)("options", "selected"), _dec7 = (0, _object.computed)("multiSelectId", "name"), _dec(_class = _dec2(_class = _dec3(_class = _dec4(_class = (_class2 = class EfsMultiSelect extends _component2.default {
    constructor() {
      super(...arguments);

      _defineProperty(this, "name", "");

      _defineProperty(this, "label", "");

      _defineProperty(this, "placeholder", "Select options...");

      _defineProperty(this, "disabled", false);

      _defineProperty(this, "required", false);

      _initializerDefineProperty(this, "isEmpty", _descriptor, this);
    }

    get selectedObjects() {
      return this.selected.map(selectedValue => this.options.find(_ref => {
        let {
          value
        } = _ref;
        return value === selectedValue;
      }));
    }

    get dataTestId() {
      if (this.name) {
        return this.name;
      }

      return this.multiSelectId;
    }

    get multiSelectId() {
      return `multi-select-${(0, _genUuid.default)(this)}`;
    }

    onChange(selectedOptions) {
      const values = selectedOptions.map(_ref2 => {
        let {
          value
        } = _ref2;
        return value;
      });
      this.onSelect(values);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "isEmpty", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "selectedObjects", [_dec6], Object.getOwnPropertyDescriptor(_class2.prototype, "selectedObjects"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "dataTestId", [_dec7], Object.getOwnPropertyDescriptor(_class2.prototype, "dataTestId"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "onChange", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "onChange"), _class2.prototype)), _class2)) || _class) || _class) || _class) || _class);
  _exports.default = EfsMultiSelect;
});