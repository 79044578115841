define("efs-components/components/efs-icon", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "efs-components/utils/gen-uuid"], function (_exports, _component, _templateFactory, _component2, _genUuid) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <i
    class={{this.className}}
    data-test-icon-id={{this.dataTestId}}
    data-test-icon={{@name}}
    id={{this.dataTestId}}
    ...attributes
  >
    {{yield}}
  </i>
  
  */
  {
    "id": "3vPUIru8",
    "block": "[[[11,\"i\"],[16,0,[30,0,[\"className\"]]],[16,\"data-test-icon-id\",[30,0,[\"dataTestId\"]]],[16,\"data-test-icon\",[30,1]],[16,1,[30,0,[\"dataTestId\"]]],[17,2],[12],[1,\"\\n  \"],[18,3,null],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@name\",\"&attrs\",\"&default\"],false,[\"yield\"]]",
    "moduleName": "efs-components/components/efs-icon.hbs",
    "isStrictMode": false
  });

  class EfsIconComponent extends _component2.default {
    get className() {
      const classNames = ["icon", this.iconName];

      if (this.args.primary) {
        classNames.push("icon--primary");
      }

      if (this.args.class) {
        classNames.push(...this.args.class.split(" "));
      }

      return classNames.join(" ");
    }

    get dataTestId() {
      return this.args.id || this.iconId;
    }

    get iconId() {
      return `${this.iconName}-${(0, _genUuid.default)(this)}`;
    }

    get iconName() {
      if (this.args.name) {
        return `icon-${this.args.name}`;
      }

      return "";
    }

  }

  _exports.default = EfsIconComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, EfsIconComponent);
});