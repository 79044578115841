define("efs-components/templates/components/efs-inline-message", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "KdZzXJUW",
    "block": "[[[10,1],[15,0,[30,0,[\"iconClassName\"]]],[15,\"data-test-inline-message-icon\",[30,0,[\"dataTestId\"]]],[12],[1,\"\\n  \"],[8,[39,0],null,[[\"@name\",\"@id\"],[[30,0,[\"iconName\"]],[30,0,[\"iconId\"]]]],null],[1,\"\\n\"],[13],[1,\"\\n\"],[10,1],[14,0,\"inline-message__text\"],[15,\"data-test-inline-message-text\",[30,0,[\"dataTestId\"]]],[12],[1,\"\\n  \"],[18,1,null],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"&default\"],false,[\"efs-icon\",\"yield\"]]",
    "moduleName": "efs-components/templates/components/efs-inline-message.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});