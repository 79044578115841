define("efs-components/components/efs-logo-axcient-x", ["exports", "@ember-decorators/component", "@ember/component", "efs-components/utils/gen-uuid", "efs-components/templates/components/efs-logo-axcient-x"], function (_exports, _component, _component2, _genUuid, _efsLogoAxcientX) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _class;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  let EfsLogoAxcientX = (_dec = (0, _component.layout)(_efsLogoAxcientX.default), _dec2 = (0, _component.tagName)("svg"), _dec3 = (0, _component.classNames)("logo", "logo--axcient-x"), _dec4 = (0, _component.classNameBindings)("center:logo--center"), _dec5 = (0, _component.attributeBindings)("dataTestId:data-test-logo-axcient-x", "name", "viewBox", "xmlns", "xlink:xmlns:xlink"), _dec(_class = _dec2(_class = _dec3(_class = _dec4(_class = _dec5(_class = class EfsLogoAxcientX extends _component2.default {
    constructor() {
      super(...arguments);

      // place simple properties and attributes in alphabetical order here:
      _defineProperty(this, "center", false);

      _defineProperty(this, "dataTestId", null);

      _defineProperty(this, "name", "");

      _defineProperty(this, "viewBox", "0 0 40 40");

      _defineProperty(this, "xmlns", "http://www.w3.org/2000/svg");

      _defineProperty(this, "xlink", "http://www.w3.org/1999/xlink");
    }

    // place complex computed properties here:
    get logoId() {
      return `axcient-x-logo-${(0, _genUuid.default)(this)}`;
    } // place methods and actions here:


    init() {
      super.init(...arguments);

      if (!this.dataTestId) {
        if (this.name) {
          this.set("dataTestId", this.name);
        } else {
          this.set("dataTestId", this.logoId);
        }
      }
    }

  }) || _class) || _class) || _class) || _class) || _class);
  _exports.default = EfsLogoAxcientX;
});