define("efs-components/components/efs-main-menu-links", ["exports", "@ember-decorators/component", "@ember/component", "efs-components/utils/gen-uuid"], function (_exports, _component, _component2, _genUuid) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  let EfsMainMenuLinks = (_dec = (0, _component.tagName)("ul"), _dec2 = (0, _component.classNames)("main-menu-links"), _dec3 = (0, _component.attributeBindings)("dataTestId:data-test-main-menu-links", "role"), _dec(_class = _dec2(_class = _dec3(_class = class EfsMainMenuLinks extends _component2.default {
    constructor() {
      super(...arguments);

      // place simple properties and attributes in alphabetical order here:
      _defineProperty(this, "dataTestId", null);

      _defineProperty(this, "name", "");
    }

    // place complex computed properties here:
    get linksId() {
      return `menu-links-${(0, _genUuid.default)(this)}`;
    } // place methods and actions here:


    init() {
      super.init(...arguments);

      if (!this.dataTestId) {
        if (this.name) {
          this.set("dataTestId", this.name);
        } else {
          this.set("dataTestId", this.linksId);
        }
      }
    }

  }) || _class) || _class) || _class);
  _exports.default = EfsMainMenuLinks;
});