define("efs-components/components/efs-modal-body", ["exports", "@ember/component", "@ember/template-factory", "@ember/object", "@glimmer/component"], function (_exports, _component, _templateFactory, _object, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="modal__body" data-test-modal-body={{this.dataTestId}} ...attributes>
    {{#if (has-block)}}
      {{yield}}
    {{else}}
      {{this.body}}
      {{#if this.mustAcknowledge}}
        <EfsCheckbox
          @checked={{this.hasAcknowledged}}
          class="modal__body-acknowledgement"
          @label={{this.acknowledgementLabel}}
          @name={{concat "acknowledge-" this.dataTestId}}
          @onCheck={{action this.toggleAcknowledgement}}
        />
      {{/if}}
    {{/if}}
  </div>
  */
  {
    "id": "RdJW5LpE",
    "block": "[[[11,0],[24,0,\"modal__body\"],[16,\"data-test-modal-body\",[30,0,[\"dataTestId\"]]],[17,1],[12],[1,\"\\n\"],[41,[48,[30,2]],[[[1,\"    \"],[18,2,null],[1,\"\\n\"]],[]],[[[1,\"    \"],[1,[30,0,[\"body\"]]],[1,\"\\n\"],[41,[30,0,[\"mustAcknowledge\"]],[[[1,\"      \"],[8,[39,3],[[24,0,\"modal__body-acknowledgement\"]],[[\"@checked\",\"@label\",\"@name\",\"@onCheck\"],[[30,0,[\"hasAcknowledged\"]],[30,0,[\"acknowledgementLabel\"]],[28,[37,4],[\"acknowledge-\",[30,0,[\"dataTestId\"]]],null],[28,[37,5],[[30,0],[30,0,[\"toggleAcknowledgement\"]]],null]]],null],[1,\"\\n\"]],[]],null]],[]]],[13]],[\"&attrs\",\"&default\"],false,[\"if\",\"has-block\",\"yield\",\"efs-checkbox\",\"concat\",\"action\"]]",
    "moduleName": "efs-components/components/efs-modal-body.hbs",
    "isStrictMode": false
  });

  let EfsModalBodyComponent = (_class = class EfsModalBodyComponent extends _component2.default {
    get acknowledgementLabel() {
      return this.args.acknowledgementLabel || "";
    }

    get body() {
      return this.args.body || "";
    }

    get dataTestId() {
      return this.args.dataTestId || "default-modal-body";
    }

    get hasAcknowledged() {
      return this.args.hasAcknowledged || false;
    }

    get mustAcknowledge() {
      return this.args.mustAcknowledge || false;
    }

    get onToggleAcknowledgement() {
      return this.args.onToggleAcknowledgement || null;
    }

    toggleAcknowledgement(checked) {
      this.onToggleAcknowledgement(checked);
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "toggleAcknowledgement", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "toggleAcknowledgement"), _class.prototype)), _class);
  _exports.default = EfsModalBodyComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, EfsModalBodyComponent);
});