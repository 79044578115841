define("efs-components/components/efs-select", ["exports", "@ember-decorators/component", "@ember/object", "@ember/object/computed", "@ember/component", "efs-components/utils/gen-uuid", "efs-components/templates/components/efs-select"], function (_exports, _component, _object, _computed, _component2, _genUuid, _efsSelect) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _class, _class2, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let EfsSelect = (_dec = (0, _component.layout)(_efsSelect.default), _dec2 = (0, _component.classNames)("select"), _dec3 = (0, _component.classNameBindings)("error:select--error", "invalid:select--invalid"), _dec4 = (0, _component.attributeBindings)("dataTestId:data-test-select"), _dec5 = (0, _computed.notEmpty)("iconName"), _dec6 = (0, _object.computed)("error", "invalid"), _dec7 = (0, _object.computed)("dataTestId", "error", "invalid"), _dec8 = (0, _object.computed)("error", "invalid"), _dec9 = (0, _object.computed)("error", "invalid"), _dec10 = (0, _object.computed)("error", "invalid"), _dec11 = (0, _object.computed)("options"), _dec(_class = _dec2(_class = _dec3(_class = _dec4(_class = (_class2 = class EfsSelect extends _component2.default {
    constructor() {
      super(...arguments);

      // place simple properties and attributes in alphabetical order here:
      _defineProperty(this, "active", false);

      _defineProperty(this, "dataTestId", null);

      _defineProperty(this, "disabled", false);

      _defineProperty(this, "error", false);

      _defineProperty(this, "for", "");

      _defineProperty(this, "invalid", false);

      _defineProperty(this, "label", "");

      _defineProperty(this, "message", "");

      _defineProperty(this, "name", "");

      _defineProperty(this, "options", null);

      _defineProperty(this, "required", false);

      _defineProperty(this, "tabindex", 0);

      _initializerDefineProperty(this, "iconVisible", _descriptor, this);
    }

    onSelect() {}

    // place complex computed properties here:
    get iconClassName() {
      if (this.error) {
        return "select__icon select--error__icon";
      }

      if (this.invalid) {
        return "select__icon select--invalid__icon";
      }

      return "";
    }

    get iconId() {
      if (this.error) {
        return `${this.dataTestId}-error-icon`;
      }

      if (this.invalid) {
        return `${this.dataTestId}-invalid-icon`;
      }

      return "";
    }

    get iconName() {
      if (this.error) {
        return "alarm";
      }

      if (this.invalid) {
        return "alarm-circular";
      }

      return "";
    }

    get messageClassName() {
      if (this.error) {
        return "select__message select--error__message";
      }

      if (this.invalid) {
        return "select__message select--invalid__message";
      }

      return "select__message";
    }

    get selectClassName() {
      if (this.error) {
        return "select__select select--error__select";
      }

      if (this.invalid) {
        return "select__select select--invalid__select";
      }

      return "select__select";
    }

    get selectId() {
      return `select-${(0, _genUuid.default)(this)}`;
    }

    get selectOptions() {
      return (this.options || []).map(option => ({
        value: option.value || "",
        label: option.label || "",
        selected: option.selected || false,
        disabled: option.disabled || false
      }));
    } // place methods and actions here:


    init() {
      super.init(...arguments);

      if (!this.dataTestId) {
        if (this.name) {
          this.set("dataTestId", this.name);
        } else {
          this.set("dataTestId", this.selectId);
        }
      }
    }

    didRender() {
      super.didRender(...arguments);
      let selectedValues = (this.options || []).filter(opt => opt.selected).map(opt => opt.value);
      let options = this.element.querySelectorAll("option") || [];
      let selectedOptions = []; // ensure all options are de-selected

      options.forEach(opt => {
        opt.selected = false;

        if (selectedValues.includes(opt.value)) {
          selectedOptions.push(opt);
        }
      }); // select options that should be selected

      selectedOptions.forEach(opt => {
        opt.selected = true;
      });
    }

    optionSelected(value) {
      this.onSelect(value);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "iconVisible", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "iconClassName", [_dec6], Object.getOwnPropertyDescriptor(_class2.prototype, "iconClassName"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "iconId", [_dec7], Object.getOwnPropertyDescriptor(_class2.prototype, "iconId"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "iconName", [_dec8], Object.getOwnPropertyDescriptor(_class2.prototype, "iconName"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "messageClassName", [_dec9], Object.getOwnPropertyDescriptor(_class2.prototype, "messageClassName"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "selectClassName", [_dec10], Object.getOwnPropertyDescriptor(_class2.prototype, "selectClassName"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "selectOptions", [_dec11], Object.getOwnPropertyDescriptor(_class2.prototype, "selectOptions"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "optionSelected", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "optionSelected"), _class2.prototype)), _class2)) || _class) || _class) || _class) || _class);
  _exports.default = EfsSelect;
});