define("efs-components/templates/components/efs-table-header-checkmark", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "FMMtE8nm",
    "block": "[[[41,[48,[30,1]],[[[1,\"  \"],[18,1,[[30,0,[\"selected\"]],[28,[37,3],[[30,0],\"toggleSelected\"],null],[30,0,[\"dataTestId\"]]]],[1,\"\\n\"]],[]],[[[1,\"  \"],[11,\"button\"],[24,0,\"table__header-button\"],[16,\"data-test-table-header-checkmark-button\",[30,0,[\"dataTestId\"]]],[16,\"data-tooltip\",[52,[30,0,[\"checked\"]],[30,0,[\"checkedTooltip\"]],[30,0,[\"uncheckedTooltip\"]]]],[24,4,\"button\"],[4,[38,4],[\"click\",[28,[37,3],[[30,0],\"toggleSelected\",[30,0,[\"checked\"]]],null]],null],[12],[1,\"\\n    \"],[8,[39,5],null,[[\"@name\",\"@id\"],[\"checkmark\",[28,[37,6],[[30,0,[\"dataTestId\"]],\"-icon\"],null]]],null],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]]]],[\"&default\"],false,[\"if\",\"has-block\",\"yield\",\"action\",\"on\",\"efs-icon\",\"concat\"]]",
    "moduleName": "efs-components/templates/components/efs-table-header-checkmark.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});