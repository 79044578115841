define("efs-components/services/flag-message", ["exports", "@ember/object", "@ember/service", "@ember/runloop", "@ember/array"], function (_exports, _object, _service, _runloop, _array) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _class;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  const DEFAULT_TIMEOUT = 4000;
  let FlagMessageService = (_dec = (0, _object.computed)("current.closable"), _dec2 = (0, _object.computed)("current.code"), _dec3 = (0, _object.computed)("queue.length"), _dec4 = (0, _object.computed)("current.message"), _dec5 = (0, _object.computed)("queue.length"), _dec6 = (0, _object.computed)("current.type"), _dec7 = (0, _object.computed)("current.type"), _dec8 = (0, _object.computed)("current.type"), _dec9 = (0, _object.computed)("current.type"), (_class = class FlagMessageService extends _service.default {
    constructor() {
      super(...arguments);

      _defineProperty(this, "timeout", DEFAULT_TIMEOUT);

      _defineProperty(this, "queue", null);
    }

    init() {
      super.init(...arguments);
      this.set("queue", (0, _array.A)());
    }

    get closable() {
      return this.current ? this.current.closable : null;
    }

    get code() {
      return this.current ? this.current.code : "";
    }

    get current() {
      return this.queue.length !== 0 ? this.queue[0] : null;
    }

    get message() {
      return this.current ? this.current.message : "";
    }

    get isActive() {
      if (this.queue.length !== 0) {
        return true;
      }

      return false;
    }

    get isInfo() {
      return this.current ? this.current.type === "info" : false;
    }

    get isSuccess() {
      return this.current ? this.current.type === "success" : false;
    }

    get isWarning() {
      return this.current ? this.current.type === "warning" : false;
    }

    get isError() {
      return this.current ? this.current.type === "error" : false;
    }

    _add(alert) {
      let removeLater = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
      let timeout = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : DEFAULT_TIMEOUT;
      this.queue.pushObject(alert);
      /* eslint-disable-next-line ember/no-get */

      let wait = this.get("queue.length") * timeout;

      if (removeLater) {
        (0, _runloop.later)(this, () => this.remove(alert), wait);
      }

      return alert;
    }

    remove(alert) {
      this.queue.removeObject(alert);
      return this;
    }

    clear() {
      this.queue.clear();
      return this;
    }

    info(message) {
      let code = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
      let closable = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
      let alert = {
        type: "info",
        message,
        code,
        closable
      };
      return this._add(alert, !closable, this.timeout);
    }

    success(message) {
      let code = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
      let closable = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
      let alert = {
        type: "success",
        message,
        code,
        closable
      };
      return this._add(alert, !closable, this.timeout);
    }

    warn(message) {
      let code = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
      let closable = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
      let alert = {
        type: "warning",
        message,
        code,
        closable
      };
      return this._add(alert, !closable, this.timeout);
    }

    error(message) {
      let code = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
      let closable = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
      let alert = {
        type: "error",
        message,
        code,
        closable
      };
      return this._add(alert, !closable, this.timeout);
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "closable", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "closable"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "code", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "code"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "current", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "current"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "message", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "message"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "isActive", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "isActive"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "isInfo", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "isInfo"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "isSuccess", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "isSuccess"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "isWarning", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "isWarning"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "isError", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "isError"), _class.prototype)), _class));
  _exports.default = FlagMessageService;
});