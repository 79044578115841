define("efs-components/templates/components/ax-breadcrumbs/path-segment", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "kLKBluwv",
    "block": "[[[10,1],[14,0,\"breadcrumbs__separator\"],[15,\"data-test-breadcrumbs-separator\",[30,0,[\"dataTestId\"]]],[12],[1,\"\\n  /\\n\"],[13],[1,\"\\n\"],[18,1,null],[1,\"\\n\"]],[\"&default\"],false,[\"yield\"]]",
    "moduleName": "efs-components/templates/components/ax-breadcrumbs/path-segment.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});