define("efs-components/components/ax-breadcrumbs/path-segment", ["exports", "@ember-decorators/component", "@ember/component", "efs-components/templates/components/ax-breadcrumbs/path-segment", "efs-components/utils/gen-uuid"], function (_exports, _component, _component2, _pathSegment, _genUuid) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  let PathSegment = (_dec = (0, _component.layout)(_pathSegment.default), _dec2 = (0, _component.tagName)("li"), _dec3 = (0, _component.classNames)("breadcrumbs__item"), _dec4 = (0, _component.attributeBindings)("dataTestId:data-test-breadcrumbs-segment", "name"), _dec(_class = _dec2(_class = _dec3(_class = _dec4(_class = class PathSegment extends _component2.default {
    constructor() {
      super(...arguments);

      _defineProperty(this, "dataTestId", "");

      _defineProperty(this, "name", "");
    }

    get crumbId() {
      return `path-segment-${(0, _genUuid.default)(this)}`;
    }

    init() {
      super.init(...arguments);

      if (!this.dataTestId) {
        this.dataTestId = this.name || this.crumbId;
      }
    }

  }) || _class) || _class) || _class) || _class);
  _exports.default = PathSegment;
});