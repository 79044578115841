define("efs-components/templates/components/efs-status-pill", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "i9ZEtQca",
    "block": "[[[41,[48,[30,1]],[[[1,\"  \"],[18,1,null],[1,\"\\n\"]],[]],[[[1,\"  \"],[1,[30,0,[\"label\"]]],[1,\"\\n\"]],[]]]],[\"&default\"],false,[\"if\",\"has-block\",\"yield\"]]",
    "moduleName": "efs-components/templates/components/efs-status-pill.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});