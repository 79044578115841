define("efs-components/components/efs-table-header-sorter", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/object", "efs-components/utils/gen-uuid", "efs-components/utils/sort"], function (_exports, _component, _templateFactory, _component2, _object, _genUuid, _sort) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <span
    class="table__header-sorter"
    data-test-table-header-sorter={{this.dataTestId}}
    ...attributes
  >
    {{#if (has-block)}}
      {{yield}}
    {{else}}
      {{this.value}}
    {{/if}}
    <button
      type="button"
      class="table__header-button"
      data-test-table-header-sorter-button={{this.dataTestId}}
      data-test-sort={{@sortDirection}}
      {{on "click" this.changeSort}}
    >
      <EfsIcon
        @name={{this.sortIcon}}
        @id={{concat this.dataTestId "-icon"}}
        @primary={{this.isPrimary}}
      />
    </button>
  </span>
  */
  {
    "id": "E0kcIcEG",
    "block": "[[[11,1],[24,0,\"table__header-sorter\"],[16,\"data-test-table-header-sorter\",[30,0,[\"dataTestId\"]]],[17,1],[12],[1,\"\\n\"],[41,[48,[30,3]],[[[1,\"    \"],[18,3,null],[1,\"\\n\"]],[]],[[[1,\"    \"],[1,[30,0,[\"value\"]]],[1,\"\\n\"]],[]]],[1,\"  \"],[11,\"button\"],[24,0,\"table__header-button\"],[16,\"data-test-table-header-sorter-button\",[30,0,[\"dataTestId\"]]],[16,\"data-test-sort\",[30,2]],[24,4,\"button\"],[4,[38,3],[\"click\",[30,0,[\"changeSort\"]]],null],[12],[1,\"\\n    \"],[8,[39,4],null,[[\"@name\",\"@id\",\"@primary\"],[[30,0,[\"sortIcon\"]],[28,[37,5],[[30,0,[\"dataTestId\"]],\"-icon\"],null],[30,0,[\"isPrimary\"]]]],null],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"&attrs\",\"@sortDirection\",\"&default\"],false,[\"if\",\"has-block\",\"yield\",\"on\",\"efs-icon\",\"concat\"]]",
    "moduleName": "efs-components/components/efs-table-header-sorter.hbs",
    "isStrictMode": false
  });

  let EfsTableHeaderSorterComponent = (_class = class EfsTableHeaderSorterComponent extends _component2.default {
    constructor() {
      super(...arguments);

      _defineProperty(this, "cellId", `table-header-sorter-${(0, _genUuid.default)(this)}`);
    }

    get dataTestId() {
      return this.name || this.cellId;
    }

    get name() {
      return this.args.name || "";
    }

    get value() {
      return this.args.value || "";
    }

    get nextSortDirection() {
      if (this.args.sortDirection === _sort.default.UNSORTED) {
        return _sort.default.ASCENDING;
      }

      if (this.args.sortDirection === _sort.default.ASCENDING) {
        return _sort.default.DESCENDING;
      }

      return _sort.default.UNSORTED;
    }

    get sortIcon() {
      if (this.args.sortDirection === _sort.default.UNSORTED) {
        return "dropdown-arrows";
      }

      if (this.args.sortDirection === _sort.default.ASCENDING) {
        return "triangle-arrow-down";
      }

      return "triangle-arrow-up";
    }

    get isPrimary() {
      return this.args.sortDirection === _sort.default.ASCENDING || this.args.sortDirection === _sort.default.DESCENDING;
    }

    changeSort() {
      this.args.onChangeSortDirection(this.nextSortDirection);
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "changeSort", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "changeSort"), _class.prototype)), _class);
  _exports.default = EfsTableHeaderSorterComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, EfsTableHeaderSorterComponent);
});