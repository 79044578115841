define("efs-components/templates/components/efs-horizontal-spacer", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "hAPIZuYV",
    "block": "[[[41,[30,0,[\"label\"]],[[[1,\"  \"],[10,\"label\"],[14,0,\"horizontal-spacer__label\"],[15,\"for\",[30,0,[\"spacerId\"]]],[15,\"data-test-horizontal-spacer-label\",[30,0,[\"dataTestId\"]]],[12],[1,\"\\n    \"],[1,[30,0,[\"label\"]]],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[10,0],[14,0,\"horizontal-spacer__object-container\"],[15,1,[30,0,[\"spacerId\"]]],[15,\"data-test-horizontal-spacer-object-container\",[30,0,[\"dataTestId\"]]],[12],[1,\"\\n  \"],[18,1,null],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"&default\"],false,[\"if\",\"yield\"]]",
    "moduleName": "efs-components/templates/components/efs-horizontal-spacer.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});