define("efs-components/templates/components/efs-button", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "aOzFQv0j",
    "block": "[[[10,0],[14,0,\"button__content\"],[15,\"data-test-button-content\",[30,0,[\"dataTestId\"]]],[12],[1,\"\\n\"],[41,[28,[37,1],[[30,0,[\"hasIcon\"]],[28,[37,2],[[30,0,[\"iconRight\"]]],null]],null],[[[1,\"    \"],[10,1],[14,0,\"button__icon\"],[14,\"aria-hidden\",\"true\"],[15,\"data-test-button-icon\",[30,0,[\"dataTestId\"]]],[12],[1,\"\\n      \"],[8,[39,3],null,[[\"@name\",\"@id\"],[[30,0,[\"icon\"]],[28,[37,4],[\"icon-for-\",[30,0,[\"dataTestId\"]]],null]]],null],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[48,[30,1]],[[[1,\"    \"],[10,1],[14,0,\"button__text\"],[15,\"data-test-button-text\",[30,0,[\"dataTestId\"]]],[12],[1,\"\\n      \"],[18,1,null],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],[[[41,[30,0,[\"value\"]],[[[1,\"    \"],[10,1],[14,0,\"button__text\"],[15,\"data-test-button-text\",[30,0,[\"dataTestId\"]]],[12],[1,\"\\n      \"],[1,[30,0,[\"value\"]]],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[]],null]],[]]],[1,\"\\n\"],[41,[28,[37,1],[[30,0,[\"hasIcon\"]],[30,0,[\"iconRight\"]]],null],[[[1,\"    \"],[10,1],[14,0,\"button__icon\"],[14,\"aria-hidden\",\"true\"],[15,\"data-test-button-icon\",[30,0,[\"dataTestId\"]]],[12],[1,\"\\n      \"],[8,[39,3],null,[[\"@name\",\"@id\"],[[30,0,[\"icon\"]],[28,[37,4],[\"icon-right-for-\",[30,0,[\"dataTestId\"]]],null]]],null],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null],[13]],[\"&default\"],false,[\"if\",\"and\",\"not\",\"efs-icon\",\"concat\",\"has-block\",\"yield\"]]",
    "moduleName": "efs-components/templates/components/efs-button.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});