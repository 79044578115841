define("efs-components/templates/components/efs-layout-panel-banner", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "R+jxKukr",
    "block": "[[[41,[48,[30,3]],[[[1,\"  \"],[18,3,null],[1,\"\\n\"]],[]],[[[42,[28,[37,4],[[28,[37,4],[[30,0,[\"banner\",\"queue\"]]],null]],null],null,[[[41,[30,1,[\"hasLink\"]],[[[1,\"      \"],[8,[39,5],null,[[\"@onClose\",\"@tabIndex\",\"@error\",\"@info\",\"@success\",\"@warning\",\"@closable\"],[[28,[37,6],[[30,0],\"closeBanner\",[30,1]],null],[30,0,[\"tabIndex\"]],[30,1,[\"isErr\"]],[30,1,[\"isInfo\"]],[30,1,[\"isSuccess\"]],[30,1,[\"isWarning\"]],[30,1,[\"closable\"]]]],[[\"default\"],[[[[1,\"\\n\"],[41,[30,1,[\"isExternalLink\"]],[[[1,\"          \"],[1,[30,1,[\"message\"]]],[1,\"\\n          \"],[10,3],[15,6,[30,1,[\"linkUrl\"]]],[15,0,[30,2]],[14,\"rel\",\"nofollow noopener noreferrer\"],[14,\"target\",\"_blank\"],[12],[1,\"\\n            \"],[1,[30,1,[\"linkText\"]]],[1,\"\\n          \"],[13],[1,\"\\n\"]],[]],[[[1,\"          \"],[1,[30,1,[\"message\"]]],[1,\"\\n          \"],[8,[39,7],[[16,0,[30,2]],[24,\"rel\",\"nofollow\"]],[[\"@route\"],[[30,1,[\"linkRoute\"]]]],[[\"default\"],[[[[1,\"\\n            \"],[1,[30,1,[\"linkText\"]]],[1,\"\\n          \"]],[]]]]],[1,\"\\n\"]],[]]],[1,\"      \"]],[2]]]]],[1,\"\\n\"]],[]],[[[1,\"      \"],[8,[39,5],null,[[\"@error\",\"@info\",\"@message\",\"@name\",\"@onClose\",\"@success\",\"@tabIndex\",\"@warning\",\"@closable\"],[[30,1,[\"isErr\"]],[30,1,[\"isInfo\"]],[30,1,[\"message\"]],[30,1,[\"name\"]],[28,[37,6],[[30,0],\"closeBanner\",[30,1]],null],[30,1,[\"isSuccess\"]],[30,1,[\"tabIndex\"]],[30,1,[\"isWarning\"]],[30,1,[\"closable\"]]]],null],[1,\"\\n\"]],[]]]],[1]],null]],[]]]],[\"BannerInstance\",\"linkClass\",\"&default\"],false,[\"if\",\"has-block\",\"yield\",\"each\",\"-track-array\",\"efs-banner\",\"action\",\"link-to\"]]",
    "moduleName": "efs-components/templates/components/efs-layout-panel-banner.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});