define("efs-components/components/efs-tabs", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "efs-components/utils/gen-uuid"], function (_exports, _component, _templateFactory, _component2, _genUuid) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <ul
    class={{this.classNames}}
    data-test-tabs={{this.dataTestId}}
    name={{this.name}}
    role="tablist"
    tabindex="0"
    ...attributes>
    {{yield this.dataTestId}}
  </ul>
  
  */
  {
    "id": "ke0PTTQw",
    "block": "[[[11,\"ul\"],[16,0,[30,0,[\"classNames\"]]],[16,\"data-test-tabs\",[30,0,[\"dataTestId\"]]],[16,3,[30,0,[\"name\"]]],[24,\"role\",\"tablist\"],[24,\"tabindex\",\"0\"],[17,1],[12],[1,\"\\n  \"],[18,2,[[30,0,[\"dataTestId\"]]]],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"&attrs\",\"&default\"],false,[\"yield\"]]",
    "moduleName": "efs-components/components/efs-tabs.hbs",
    "isStrictMode": false
  });

  class EfsTabsComponent extends _component2.default {
    get center() {
      return this.args.center || false;
    }

    get classNames() {
      let classes = ["tabs"];

      if (this.center) {
        classes.push("tabs--center");
      }

      if (this.primary) {
        classes.push("tabs--primary");
      }

      if (this.right) {
        classes.push("tabs--right");
      }

      return classes.join(" ");
    }

    get dataTestId() {
      return this.name || this.tabsId;
    }

    get name() {
      return this.args.name || "";
    }

    get right() {
      return this.args.right || false;
    }

    get tabsId() {
      return `tabs-${(0, _genUuid.default)(this)}`;
    }

  }

  _exports.default = EfsTabsComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, EfsTabsComponent);
});