define("efs-components/utils/sort", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const sortOrder = {
    UNSORTED: "unsorted",
    ASCENDING: "asc",
    DESCENDING: "desc"
  };
  var _default = sortOrder;
  _exports.default = _default;
});