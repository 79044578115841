define("efs-components/components/efs-button", ["exports", "@ember-decorators/component", "@ember/object/computed", "@ember/component", "efs-components/utils/gen-uuid", "efs-components/templates/components/efs-button"], function (_exports, _component, _computed, _component2, _genUuid, _efsButton) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _class, _class2, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let EfsButton = (_dec = (0, _component.layout)(_efsButton.default), _dec2 = (0, _component.tagName)("button"), _dec3 = (0, _component.classNames)("button"), _dec4 = (0, _component.classNameBindings)("iconOnly:button--icon-only", "iconRight:button--icon-right", "primary:button--primary", "white:button--white"), _dec5 = (0, _component.attributeBindings)("dataTestId:data-test-button", "data-tooltip", "data-tooltip-active", "data-tooltip-system", "disabled", "name", "tabindex", "title", "type", "value"), _dec6 = (0, _computed.notEmpty)("icon"), _dec(_class = _dec2(_class = _dec3(_class = _dec4(_class = _dec5(_class = (_class2 = class EfsButton extends _component2.default {
    constructor() {
      super(...arguments);

      // place simple properties and attributes in alphabetical order here:
      _defineProperty(this, "dataTestId", null);

      _defineProperty(this, "disabled", false);

      _defineProperty(this, "icon", "");

      _defineProperty(this, "iconOnly", false);

      _defineProperty(this, "iconRight", false);

      _defineProperty(this, "name", "");

      _defineProperty(this, "primary", false);

      _defineProperty(this, "tabindex", 0);

      _defineProperty(this, "title", "");

      _defineProperty(this, "type", "button");

      _defineProperty(this, "value", "");

      _defineProperty(this, "white", false);

      _initializerDefineProperty(this, "hasIcon", _descriptor, this);
    }

    // place complex computed properties here:
    get buttonId() {
      return `button-${(0, _genUuid.default)(this)}`;
    } // place methods and actions here:


    init() {
      super.init(...arguments);

      if (!this.dataTestId) {
        if (this.name) {
          this.set("dataTestId", this.name);
        } else {
          this.set("dataTestId", this.buttonId);
        }
      }

      if (this.disabled) {
        this.click = null;
      }
    }

    didUpdateAttrs() {
      if (this.disabled) {
        this.click = null;
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "hasIcon", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class2)) || _class) || _class) || _class) || _class) || _class);
  _exports.default = EfsButton;
});