define("efs-components/mixins/focus-trappable", ["exports", "@ember/object/mixin"], function (_exports, _mixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* eslint-disable-next-line ember/no-new-mixins */
  var _default = _mixin.default.create({
    containerNode: "",
    focusHandler: null,

    captureFocus() {
      if (this.focusHandler) {
        return;
      }

      let handler = e => {
        let selector = this.containerNode;
        let container = this.element.querySelector(selector);

        if (!container) {
          container = this.element;
        }

        if (!container.contains(e.target)) {
          e.stopPropagation();
          container.focus();
        }
      };

      this.set("focusHandler", handler);
      document.addEventListener("focus", handler, true);
    },

    restoreFocus() {
      document.removeEventListener("focus", this.focusHandler, true);
      this.set("focusHandler", null);
    }

  });

  _exports.default = _default;
});