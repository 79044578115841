define("efs-components/templates/components/efs-layout-panel-flag-message", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "HZbvwYqW",
    "block": "[[[8,[39,0],null,[[\"@message\",\"@success\",\"@warning\",\"@error\",\"@onClose\"],[[30,0,[\"message\"]],[30,0,[\"isSuccess\"]],[30,0,[\"isWarning\"]],[30,0,[\"isError\"]],[28,[37,1],[[30,0],\"closeFlag\"],null]]],null],[1,\"\\n\"]],[],false,[\"efs-flag-message\",\"action\"]]",
    "moduleName": "efs-components/templates/components/efs-layout-panel-flag-message.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});