define("efs-components/components/efs-table-body-more-cell", ["exports", "@ember-decorators/component", "@ember/object", "@ember/component", "efs-components/utils/gen-uuid", "efs-components/templates/components/efs-table-body-more-cell"], function (_exports, _component, _object, _component2, _genUuid, _efsTableBodyMoreCell) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _class, _class2;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  let EfsTableBodyMoreCell = (_dec = (0, _component.layout)(_efsTableBodyMoreCell.default), _dec2 = (0, _component.tagName)("td"), _dec3 = (0, _component.classNames)("table__body-cell", "table__body-more-cell", "table__column--more"), _dec4 = (0, _component.classNameBindings)("active:table__body-more-cell--active", "selected:table__body-row--selected", "visibleLarge:table__column--visible-l", "visibleMediumLarge:table__column--visible-ml", "visibleSmallMediumLarge:table__column--visible-sml"), _dec5 = (0, _component.attributeBindings)("dataTestId:data-test-table-body-more-cell", "name", "tabindex"), _dec6 = (0, _object.computed)("active"), _dec7 = (0, _object.computed)("active"), _dec8 = (0, _object.computed)("visibleMediumLarge", "visibleLarge"), _dec(_class = _dec2(_class = _dec3(_class = _dec4(_class = _dec5(_class = (_class2 = class EfsTableBodyMoreCell extends _component2.default {
    constructor() {
      super(...arguments);

      _defineProperty(this, "data-test-table-body-more-cell", true);

      // place simple properties and attributes in alphabetical order here:
      _defineProperty(this, "active", false);

      _defineProperty(this, "dataTestId", null);

      _defineProperty(this, "name", "");

      _defineProperty(this, "selected", false);

      _defineProperty(this, "tabindex", 0);

      _defineProperty(this, "visibleLarge", false);

      _defineProperty(this, "visibleMediumLarge", false);
    }

    // place complex computed properties here:
    get buttonClass() {
      if (this.active) {
        return "table__body-more-button--active";
      }

      return "table__body-more-button";
    }

    get cellId() {
      return `table-body-more-cell-${(0, _genUuid.default)(this)}`;
    }

    get listClass() {
      if (this.active) {
        return "table__body-more-list table__body-more-list--active";
      }

      return "table__body-more-list";
    }

    get visibleSmallMediumLarge() {
      if (this.visibleMediumLarge || this.visibleLarge) {
        return false;
      }

      return true;
    } // place methods and actions here:


    init() {
      super.init(...arguments);

      if (!this.dataTestId) {
        if (this.name) {
          this.set("dataTestId", this.name);
        } else {
          this.set("dataTestId", this.cellId);
        }
      }
    }

    toggleActive() {
      this.set("active", !this.active);
    }

  }, (_applyDecoratedDescriptor(_class2.prototype, "buttonClass", [_dec6], Object.getOwnPropertyDescriptor(_class2.prototype, "buttonClass"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "listClass", [_dec7], Object.getOwnPropertyDescriptor(_class2.prototype, "listClass"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "visibleSmallMediumLarge", [_dec8], Object.getOwnPropertyDescriptor(_class2.prototype, "visibleSmallMediumLarge"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "toggleActive", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "toggleActive"), _class2.prototype)), _class2)) || _class) || _class) || _class) || _class) || _class);
  _exports.default = EfsTableBodyMoreCell;
});