define("efs-components/templates/components/efs-radio", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "1OUNbpzR",
    "block": "[[[42,[28,[37,1],[[28,[37,1],[[30,0,[\"radioOptions\"]]],null]],null],null,[[[1,\"  \"],[10,\"label\"],[14,0,\"radio\"],[15,\"for\",[30,1,[\"id\"]]],[15,\"data-test-radio-label\",[30,1,[\"dataTestId\"]]],[12],[1,\"\\n    \"],[11,\"input\"],[16,1,[30,1,[\"id\"]]],[24,4,\"radio\"],[16,3,[30,1,[\"name\"]]],[16,\"checked\",[30,1,[\"checked\"]]],[16,2,[30,1,[\"value\"]]],[24,0,\"radio__input\"],[16,\"disabled\",[30,1,[\"disabled\"]]],[16,\"data-test-radio-input\",[30,1,[\"dataTestId\"]]],[17,2],[4,[38,2],[\"change\",[28,[37,3],[[30,0,[\"radioChecked\"]],[30,1,[\"value\"]]],null]],null],[12],[13],[1,\"\\n    \"],[10,1],[14,0,\"radio__check\"],[15,\"data-test-radio-check\",[30,1,[\"dataTestId\"]]],[12],[13],[1,\"\\n    \"],[10,1],[14,0,\"radio__label\"],[15,\"data-test-radio-label-text\",[30,1,[\"dataTestId\"]]],[12],[1,\"\\n      \"],[1,[30,1,[\"label\"]]],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[1]],null]],[\"option\",\"&attrs\"],false,[\"each\",\"-track-array\",\"on\",\"fn\"]]",
    "moduleName": "efs-components/templates/components/efs-radio.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});