define("efs-components/templates/components/efs-progress-circle", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "uqlisEpy",
    "block": "[[[10,\"svg\"],[14,0,\"progress-circle__svg\"],[15,\"data-test-progress-circle-svg\",[30,0,[\"dataTestId\"]]],[15,\"width\",[30,0,[\"size\"]]],[15,\"height\",[30,0,[\"size\"]]],[15,\"viewBox\",[29,[\"0 0 \",[30,0,[\"viewBoxSize\"]],\" \",[30,0,[\"viewBoxSize\"]]]]],[14,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[12],[1,\"\\n  \"],[10,\"circle\"],[14,0,\"progress-circle__center\"],[15,\"data-test-progress-circle-center\",[30,0,[\"dataTestId\"]]],[15,\"r\",[30,0,[\"radius\"]]],[15,\"cy\",[30,0,[\"XYcoordinates\"]]],[15,\"cx\",[30,0,[\"XYcoordinates\"]]],[15,\"stroke-width\",[30,0,[\"strokeWidth\"]]],[14,\"fill\",\"none\"],[12],[13],[1,\"\\n\\n  \"],[10,\"circle\"],[15,0,[30,0,[\"indicatorClassName\"]]],[15,\"data-test-progress-circle-indicator\",[30,0,[\"dataTestId\"]]],[14,\"transform\",\"rotate(-90,50,50)\"],[15,\"r\",[30,0,[\"radius\"]]],[15,\"cy\",[30,0,[\"XYcoordinates\"]]],[15,\"cx\",[30,0,[\"XYcoordinates\"]]],[15,\"stroke-width\",[30,0,[\"strokeWidth\"]]],[14,\"fill\",\"none\"],[15,\"stroke-dasharray\",[30,0,[\"circumference\"]]],[15,\"stroke-dashoffset\",[30,0,[\"strokeDashoffset\"]]],[12],[13],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[41,[30,0,[\"useCaption\"]],[[[1,\"  \"],[10,\"figcaption\"],[14,0,\"progress-circle__caption\"],[15,\"data-test-progress-circle-caption\",[30,0,[\"dataTestId\"]]],[12],[1,\"\\n\"],[41,[30,0,[\"hasValidPercentage\"]],[[[1,\"      \"],[1,[28,[35,1],[[30,0,[\"percentage\"]],\"%\"],null]],[1,\"\\n\"]],[]],null],[1,\"  \"],[13],[1,\"\\n\"]],[]],null]],[],false,[\"if\",\"concat\"]]",
    "moduleName": "efs-components/templates/components/efs-progress-circle.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});