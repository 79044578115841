define("efs-components/templates/components/efs-table-body-more-link", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "2KcYR5ZA",
    "block": "[[[8,[39,0],[[24,0,\"text table__body-more-link\"],[16,\"data-test-table-body-more-link-item-a\",[30,0,[\"dataTestId\"]]]],[[\"@route\",\"@activeClass\",\"@disabledClass\",\"@disabled\"],[[30,0,[\"route\"]],\"table__body-more-link--active\",\"table__body-more-link--disabled\",[30,0,[\"disabled\"]]]],[[\"default\"],[[[[1,\"\\n  \"],[1,[30,0,[\"label\"]]],[1,\"\\n\"]],[]]]]]],[],false,[\"link-to\"]]",
    "moduleName": "efs-components/templates/components/efs-table-body-more-link.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});