define("efs-components/templates/components/efs-multi-select", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "VEzYXOke",
    "block": "[[[10,\"label\"],[14,0,\"multi-select__label\"],[15,\"data-test-multi-select-label\",[30,0,[\"dataTestId\"]]],[12],[1,\"\\n  \"],[10,0],[14,0,\"multi-select__label-text\"],[15,\"data-test-multi-select-label-text\",[30,0,[\"dataTestId\"]]],[12],[1,\"\\n    \"],[1,[30,0,[\"label\"]]],[1,\"\\n  \"],[13],[1,\"\\n  \"],[8,[39,0],null,[[\"@options\",\"@selected\",\"@placeholder\",\"@searchField\",\"@renderInPlace\",\"@searchEnabled\",\"@disabled\",\"@onChange\"],[[30,0,[\"options\"]],[30,0,[\"selectedObjects\"]],[30,0,[\"placeholder\"]],\"option.label\",true,true,[30,0,[\"disabled\"]],[28,[37,1],[[30,0],\"onChange\"],null]]],[[\"default\"],[[[[1,\"\\n    \"],[10,1],[14,0,\"multi-select__option\"],[15,\"data-test-multi-select-option\",[30,0,[\"dataTestId\"]]],[12],[1,\"\\n      \"],[1,[30,1,[\"label\"]]],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[1]]]]],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"option\"],false,[\"power-select-multiple\",\"action\"]]",
    "moduleName": "efs-components/templates/components/efs-multi-select.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});