define("efs-components/templates/components/efs-table-body-checkbox", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "rHluK+F9",
    "block": "[[[8,[39,0],null,[[\"@checked\",\"@dataTestId\",\"@disabled\",\"@name\",\"@onCheck\",\"@partial\"],[[30,0,[\"checked\"]],[30,0,[\"dataTestId\"]],[30,0,[\"disabled\"]],[30,0,[\"name\"]],[28,[37,1],[[30,0],\"toggleSelected\"],null],[30,0,[\"partial\"]]]],null],[1,\"\\n\"]],[],false,[\"efs-checkbox\",\"action\"]]",
    "moduleName": "efs-components/templates/components/efs-table-body-checkbox.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});