define("efs-components/templates/components/efs-accordion-header", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "hsqUpsfj",
    "block": "[[[11,\"button\"],[24,0,\"accordion__button\"],[16,\"data-test-accordion-header-button\",[30,0,[\"dataTestId\"]]],[24,4,\"button\"],[4,[38,0],[\"click\",[30,0,[\"toggleRow\"]]],null],[12],[1,\"\\n  \"],[8,[39,1],null,[[\"@class\",\"@name\",\"@id\"],[\"accordion__arrow\",[30,0,[\"iconName\"]],[28,[37,2],[\"arrow-icon-for-\",[30,0,[\"dataTestId\"]]],null]]],null],[1,\"\\n  \"],[10,1],[14,0,\"accordion__label\"],[15,\"data-test-accordion-header-label\",[30,0,[\"dataTestId\"]]],[12],[1,\"\\n\"],[41,[48,[30,1]],[[[1,\"      \"],[18,1,null],[1,\"\\n\"]],[]],[[[1,\"      \"],[1,[30,0,[\"title\"]]],[1,\"\\n\"]],[]]],[1,\"  \"],[13],[1,\"\\n\"],[13]],[\"&default\"],false,[\"on\",\"efs-icon\",\"concat\",\"if\",\"has-block\",\"yield\"]]",
    "moduleName": "efs-components/templates/components/efs-accordion-header.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});