define("efs-components/components/efs-modal", ["exports", "@ember-decorators/component", "@ember/object", "@ember/component", "@glimmer/tracking", "@ember/runloop", "efs-components/utils/gen-uuid", "efs-components/templates/components/efs-modal", "efs-components/mixins/focus-trappable", "efs-components/utils/key-codes"], function (_exports, _component, _object, _component2, _tracking, _runloop, _genUuid, _efsModal, _focusTrappable, _keyCodes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _class, _class2, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const CLASS_NAME_NO_SCROLL = "noscroll";
  const CLASS_NAME_BACKDROP = "modal__backdrop";
  const CLASS_NAME_CONTENT = "modal__content";
  let EfsModal = (_dec = (0, _component.layout)(_efsModal.default), _dec2 = (0, _component.classNames)("modal"), _dec3 = (0, _component.classNameBindings)("active:modal--active"), _dec4 = (0, _component.attributeBindings)("dataTestId:data-test-modal", "hidden:aria-hidden", "modalId:aria-labeledby", "name", "role"), _dec5 = (0, _object.computed)("active"), _dec6 = (0, _object.computed)("active"), _dec7 = (0, _object.computed)("hasAcknowledged", "mustAcknowledge"), _dec(_class = _dec2(_class = _dec3(_class = _dec4(_class = (_class2 = class EfsModal extends _component2.default.extend(_focusTrappable.default) {
    constructor() {
      super(...arguments);

      // place simple properties and attributes in alphabetical order here:
      _defineProperty(this, "acknowledgementLabel", "");

      _defineProperty(this, "activatedBy", null);

      _defineProperty(this, "active", false);

      _defineProperty(this, "body", "");

      _defineProperty(this, "closable", true);

      _defineProperty(this, "containerNode", ".modal__content");

      _defineProperty(this, "dataTestId", null);

      _initializerDefineProperty(this, "hasAcknowledged", _descriptor, this);

      _defineProperty(this, "mustAcknowledge", false);

      _defineProperty(this, "name", "");

      _defineProperty(this, "role", "dialog");

      _defineProperty(this, "subtitle", "");

      _defineProperty(this, "title", "");
    }

    onClosed() {} // place complex computed properties here:


    get dialogClassName() {
      if (this.active) {
        return "modal__dialog modal--active__dialog";
      }

      return "modal__dialog";
    }

    get hidden() {
      if (this.active) {
        return "false";
      }

      return "true";
    }

    get primaryButtonIsDisabled() {
      return this.mustAcknowledge ? !this.hasAcknowledged : false;
    }

    get modalId() {
      return `modal-${(0, _genUuid.default)(this)}`;
    }

    init() {
      super.init(...arguments);

      if (!this.dataTestId) {
        this.dataTestId = this.name ? this.name : this.modalId;
      }
    }

    didUpdateAttrs() {
      if (this.active) {
        if (!this.activatedBy) {
          this.set("activatedBy", document.activeElement);
        }

        this.addBackdrop();
        this.captureFocus();
        return;
      }

      this.removeBackdrop().then(() => {
        this.restoreFocus();

        if (this.activatedBy) {
          this.activatedBy.focus();
          this.set("activatedBy", null);
        }

        this.onClosed();
      });
    }

    closeModal(accepted) {
      this.removeBackdrop().then(() => {
        this.restoreFocus();
        this.onClose(accepted);
      });
    }

    toggleAcknowledgement(value) {
      this.onAcknowledged(value);
    } // place methods and actions here:


    addBackdrop() {
      let backdrop = document.body.querySelector(`.${CLASS_NAME_BACKDROP}`);

      if (backdrop) {
        return;
      }

      backdrop = document.createElement("div");
      backdrop.className = CLASS_NAME_BACKDROP;
      backdrop.dataset.testModalBackdrop = this.dataTestId;
      document.body.prepend(backdrop);
      document.body.classList.add(CLASS_NAME_NO_SCROLL);
      this.element.addEventListener("click", this._clickHandler.bind(this));
      this.element.style = "display: block";
      this.element.querySelector(`.${CLASS_NAME_CONTENT}`).focus();
    }

    removeBackdrop() {
      let backdrop = document.body.querySelector(`.${CLASS_NAME_BACKDROP}`);

      if (backdrop) {
        backdrop.remove();
      }

      document.body.classList.remove(CLASS_NAME_NO_SCROLL);
      this.element.removeEventListener("click", this._clickHandler.bind(this));
      return new Promise(resolve => {
        (0, _runloop.later)(this, function hideModalLater() {
          this.element.style = "display: none";
          resolve();
        }, 120);
      });
    }

    _clickHandler(event) {
      let dialog = this.element.querySelector(`.${CLASS_NAME_CONTENT}`);

      if (dialog && dialog.contains(event.target) === false) {
        dialog.focus();
      }
    }

    onAcknowledged(value) {
      this.hasAcknowledged = value;
    }

    onClose() {
      this.set("active", false);
    }

    keyUp(event) {
      if (event.which === _keyCodes.KEY_CODE_ESCAPE) {
        return this.send("closeModal", false);
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "hasAcknowledged", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class2.prototype, "dialogClassName", [_dec5], Object.getOwnPropertyDescriptor(_class2.prototype, "dialogClassName"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "hidden", [_dec6], Object.getOwnPropertyDescriptor(_class2.prototype, "hidden"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "primaryButtonIsDisabled", [_dec7], Object.getOwnPropertyDescriptor(_class2.prototype, "primaryButtonIsDisabled"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "closeModal", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "closeModal"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "toggleAcknowledgement", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "toggleAcknowledgement"), _class2.prototype)), _class2)) || _class) || _class) || _class) || _class);
  _exports.default = EfsModal;
});