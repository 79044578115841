define("efs-components/templates/components/efs-tooltip", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "WXPImCQT",
    "block": "[[[11,\"button\"],[24,0,\"tooltip__button\"],[16,3,[29,[[30,0,[\"tooltipId\"]],\"-button\"]]],[16,\"data-test-tooltip-button\",[30,0,[\"dataTestId\"]]],[24,4,\"button\"],[4,[38,0],[\"click\",[28,[37,1],[[30,0],\"toggleSticky\"],null]],null],[12],[1,\"\\n  \"],[8,[39,2],null,[[\"@name\",\"@class\",\"@id\"],[\"tooltip\",\"tooltip__icon\",[28,[37,3],[[30,0,[\"dataTestId\"]],\"-icon\"],null]]],null],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[11,1],[16,0,[30,0,[\"balloonClassName\"]]],[16,\"for\",[29,[[30,0,[\"tooltipId\"]],\"-button\"]]],[16,\"data-test-tooltip-balloon\",[30,0,[\"dataTestId\"]]],[4,[38,0],[\"click\",[28,[37,1],[[30,0],\"toggleSticky\"],null]],null],[12],[1,\"\\n  \"],[18,1,null],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"&default\"],false,[\"on\",\"action\",\"efs-icon\",\"concat\",\"yield\"]]",
    "moduleName": "efs-components/templates/components/efs-tooltip.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});