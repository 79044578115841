define("efs-components/components/efs-modal-header", ["exports", "@ember/component", "@ember/template-factory", "@ember/object", "@glimmer/component"], function (_exports, _component, _templateFactory, _object, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div
    class="modal__header"
    data-test-modal-header={{this.dataTestId}}
    ...attributes
  >
    {{#if this.isClosable}}
      <button
        type="button"
        class="modal__close"
        title="Close this dialog window"
        data-test-modal-close={{this.dataTestId}}
        {{on "click" this.closeModal}}
      >
        <EfsIcon
          @name="close-small"
          @id={{concat this.dataTestId "-close-icon"}}
        />
      </button>
    {{/if}}
    <div
      class="text text--large text--light modal__title"
      data-test-modal-title={{this.dataTestId}}
    >
      {{#if (has-block)}}
        {{yield}}
      {{else}}
        {{this.title}}
      {{/if}}
    </div>
  </div>
  */
  {
    "id": "rlyquzPJ",
    "block": "[[[11,0],[24,0,\"modal__header\"],[16,\"data-test-modal-header\",[30,0,[\"dataTestId\"]]],[17,1],[12],[1,\"\\n\"],[41,[30,0,[\"isClosable\"]],[[[1,\"    \"],[11,\"button\"],[24,0,\"modal__close\"],[24,\"title\",\"Close this dialog window\"],[16,\"data-test-modal-close\",[30,0,[\"dataTestId\"]]],[24,4,\"button\"],[4,[38,1],[\"click\",[30,0,[\"closeModal\"]]],null],[12],[1,\"\\n      \"],[8,[39,2],null,[[\"@name\",\"@id\"],[\"close-small\",[28,[37,3],[[30,0,[\"dataTestId\"]],\"-close-icon\"],null]]],null],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null],[1,\"  \"],[10,0],[14,0,\"text text--large text--light modal__title\"],[15,\"data-test-modal-title\",[30,0,[\"dataTestId\"]]],[12],[1,\"\\n\"],[41,[48,[30,2]],[[[1,\"      \"],[18,2,null],[1,\"\\n\"]],[]],[[[1,\"      \"],[1,[30,0,[\"title\"]]],[1,\"\\n\"]],[]]],[1,\"  \"],[13],[1,\"\\n\"],[13]],[\"&attrs\",\"&default\"],false,[\"if\",\"on\",\"efs-icon\",\"concat\",\"has-block\",\"yield\"]]",
    "moduleName": "efs-components/components/efs-modal-header.hbs",
    "isStrictMode": false
  });

  let EfsModalHeaderComponent = (_class = class EfsModalHeaderComponent extends _component2.default {
    get dataTestId() {
      return this.args.dataTestId || "default-modal-header";
    }

    get isClosable() {
      return this.args.isClosable !== false;
    }

    get title() {
      return this.args.title || "";
    }

    get onClose() {
      return this.args.onClose || null;
    }

    closeModal() {
      this.onClose();
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "closeModal", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "closeModal"), _class.prototype)), _class);
  _exports.default = EfsModalHeaderComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, EfsModalHeaderComponent);
});