define("efs-components/templates/components/efs-main-menu-logo", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "sym15jIt",
    "block": "[[[8,[39,0],[[16,\"data-test-link\",[30,0,[\"dataTestId\"]]],[16,\"data-testid\",[28,[37,1],[\"link-\",[30,0,[\"dataTestId\"]]],null]]],[[\"@route\"],[\"index\"]],[[\"default\"],[[[[1,\"\\n  \"],[18,1,null],[1,\"\\n\"]],[]]]]],[1,\"\\n\"]],[\"&default\"],false,[\"link-to\",\"concat\",\"yield\"]]",
    "moduleName": "efs-components/templates/components/efs-main-menu-logo.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});