define("efs-components/components/efs-tab", ["exports", "@ember/component", "@ember/template-factory", "@ember/service", "@glimmer/component", "efs-components/utils/gen-uuid"], function (_exports, _component, _templateFactory, _service, _component2, _genUuid) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{! template-lint-disable no-nested-splattributes }}
  <li
    aria-selected={{this.isAriaSelected}}
    class={{this.tabClass}}
    data-test-tab={{this.dataTestId}}
    name={{this.name}}
    role="tab"
    ...attributes
  >
    {{#if this.label}}
      <LinkTo
        @route={{this.route}}
        @query={{if @query @query (hash)}}
        @activeClass={{this.linkActiveClass}}
        class={{this.linkBaseClass}}
        data-test-tab-link={{this.dataTestId}}
        ...attributes
      >
        {{this.label}}
      </LinkTo>
    {{else}}
      <LinkTo
        @route={{this.route}}
        @query={{if @query @query (hash)}}
        @activeClass={{this.linkActiveClass}}
        class={{this.linkBaseClass}}
        data-test-tab-link={{this.dataTestId}}
        ...attributes
      >
        {{yield}}
      </LinkTo>
    {{/if}}
  </li>
  */
  {
    "id": "Bvd9B8Gw",
    "block": "[[[11,\"li\"],[16,\"aria-selected\",[30,0,[\"isAriaSelected\"]]],[16,0,[30,0,[\"tabClass\"]]],[16,\"data-test-tab\",[30,0,[\"dataTestId\"]]],[16,3,[30,0,[\"name\"]]],[24,\"role\",\"tab\"],[17,1],[12],[1,\"\\n\"],[41,[30,0,[\"label\"]],[[[1,\"    \"],[8,[39,1],[[16,0,[30,0,[\"linkBaseClass\"]]],[16,\"data-test-tab-link\",[30,0,[\"dataTestId\"]]],[17,1]],[[\"@route\",\"@query\",\"@activeClass\"],[[30,0,[\"route\"]],[52,[30,2],[30,2],[28,[37,2],null,null]],[30,0,[\"linkActiveClass\"]]]],[[\"default\"],[[[[1,\"\\n      \"],[1,[30,0,[\"label\"]]],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"]],[]],[[[1,\"    \"],[8,[39,1],[[16,0,[30,0,[\"linkBaseClass\"]]],[16,\"data-test-tab-link\",[30,0,[\"dataTestId\"]]],[17,1]],[[\"@route\",\"@query\",\"@activeClass\"],[[30,0,[\"route\"]],[52,[30,2],[30,2],[28,[37,2],null,null]],[30,0,[\"linkActiveClass\"]]]],[[\"default\"],[[[[1,\"\\n      \"],[18,3,null],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"]],[]]],[13]],[\"&attrs\",\"@query\",\"&default\"],false,[\"if\",\"link-to\",\"hash\",\"yield\"]]",
    "moduleName": "efs-components/components/efs-tab.hbs",
    "isStrictMode": false
  });

  let EfsTabComponent = (_class = class EfsTabComponent extends _component2.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "router", _descriptor, this);
    }

    get isAriaSelected() {
      return (this.router.currentRouteName === this.route).toString();
    }

    get dataTestId() {
      return this.name || this.tabId;
    }

    get label() {
      return this.args.label || "";
    }

    get linkBaseClass() {
      return this.primary ? "tabs__link tabs--primary__link" : "tabs__link";
    }

    get linkActiveClass() {
      return this.primary ? "tabs--primary__link--active tabs__link--active" : "tabs__link--active";
    }

    get name() {
      return this.args.name || "";
    }

    get primary() {
      return this.args.primary || false;
    }

    get route() {
      return this.args.route || "";
    }

    get tabClass() {
      return this.primary ? "tabs__tab tabs--primary__tab" : "tabs__tab";
    }

    get tabId() {
      return this.args.dataTestId ? `${this.args.dataTestId}-tab-${(0, _genUuid.default)(this)}` : `tab-${(0, _genUuid.default)(this)}`;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "router", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class);
  _exports.default = EfsTabComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, EfsTabComponent);
});