define("efs-components/components/efs-inline-message", ["exports", "@ember-decorators/component", "@ember/object", "@ember/component", "efs-components/utils/gen-uuid", "efs-components/templates/components/efs-inline-message"], function (_exports, _component, _object, _component2, _genUuid, _efsInlineMessage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _class, _class2;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  let EfsInlineMessage = (_dec = (0, _component.layout)(_efsInlineMessage.default), _dec2 = (0, _component.classNames)("inline-message"), _dec3 = (0, _component.classNameBindings)("left:inline-message--left", "center:inline-message--center", "right:inline-message--right"), _dec4 = (0, _component.attributeBindings)("dataTestId:data-test-inline-message", "name"), _dec5 = (0, _object.computed)("modifier"), _dec6 = (0, _object.computed)("dataTestId", "error", "success", "warning"), _dec7 = (0, _object.computed)("error", "success", "warning"), _dec8 = (0, _object.computed)("modifier"), _dec9 = (0, _object.computed)("warning", "success", "error"), _dec(_class = _dec2(_class = _dec3(_class = _dec4(_class = (_class2 = class EfsInlineMessage extends _component2.default {
    constructor() {
      super(...arguments);

      // place simple properties and attributes in alphabetical order here:
      _defineProperty(this, "dataTestId", null);

      _defineProperty(this, "center", false);

      _defineProperty(this, "error", false);

      _defineProperty(this, "left", false);

      _defineProperty(this, "name", "");

      _defineProperty(this, "right", false);

      _defineProperty(this, "success", false);

      _defineProperty(this, "warning", false);
    }

    // place complex computed properties here:
    get iconClassName() {
      let base = "inline-message__icon";

      if (this.modifier) {
        return `${base} ${base}--${this.modifier}`;
      }

      return base;
    }

    get iconId() {
      if (this.error) {
        return `${this.dataTestId}-error-icon`;
      }

      if (this.success) {
        return `${this.dataTestId}-success-icon`;
      }

      if (this.warning) {
        return `${this.dataTestId}-warning-icon`;
      }

      return `${this.dataTestId}-info-icon`;
    }

    get iconName() {
      if (this.error) {
        return "alarm";
      }

      if (this.warning) {
        return "alarm-circular";
      }

      if (this.success) {
        return "checkmark-circular";
      }

      return "information";
    }

    get messageId() {
      if (this.modifier) {
        return `inline-message-${this.modifier}-${(0, _genUuid.default)(this)}`;
      }

      return `inline-message-${(0, _genUuid.default)(this)}`;
    }

    get modifier() {
      if (this.success) {
        return "success";
      }

      if (this.warning) {
        return "warning";
      }

      if (this.error) {
        return "error";
      }

      return "";
    } // place methods and actions here:


    init() {
      super.init(...arguments);

      if (!this.dataTestId) {
        if (this.name) {
          this.set("dataTestId", this.name);
        } else {
          this.set("dataTestId", this.messageId);
        }
      }
    }

  }, (_applyDecoratedDescriptor(_class2.prototype, "iconClassName", [_dec5], Object.getOwnPropertyDescriptor(_class2.prototype, "iconClassName"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "iconId", [_dec6], Object.getOwnPropertyDescriptor(_class2.prototype, "iconId"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "iconName", [_dec7], Object.getOwnPropertyDescriptor(_class2.prototype, "iconName"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "messageId", [_dec8], Object.getOwnPropertyDescriptor(_class2.prototype, "messageId"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "modifier", [_dec9], Object.getOwnPropertyDescriptor(_class2.prototype, "modifier"), _class2.prototype)), _class2)) || _class) || _class) || _class) || _class);
  _exports.default = EfsInlineMessage;
});