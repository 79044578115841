define("efs-components/components/efs-table-footer-cell", ["exports", "@ember-decorators/component", "@ember/object", "@ember/component", "efs-components/utils/gen-uuid", "efs-components/templates/components/efs-table-footer-cell"], function (_exports, _component, _object, _component2, _genUuid, _efsTableFooterCell) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _class, _class2;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  let EfsTableFooterCell = (_dec = (0, _component.layout)(_efsTableFooterCell.default), _dec2 = (0, _component.tagName)("td"), _dec3 = (0, _component.classNames)("table__footer-cell"), _dec4 = (0, _component.classNameBindings)("visibleLarge:table__column--visible-l", "visibleMediumLarge:table__column--visible-ml", "visibleSmallMediumLarge:table__column--visible-sml"), _dec5 = (0, _component.attributeBindings)("colspan", "dataTestId:data-test-table-footer-cell"), _dec6 = (0, _object.computed)("visibleMediumLarge", "visibleSmallMediumLarge"), _dec(_class = _dec2(_class = _dec3(_class = _dec4(_class = _dec5(_class = (_class2 = class EfsTableFooterCell extends _component2.default {
    constructor() {
      super(...arguments);

      // place simple properties and attributes in alphabetical order here:
      _defineProperty(this, "colspan", 1);

      _defineProperty(this, "dataTestId", null);

      _defineProperty(this, "name", "");

      _defineProperty(this, "tabindex", 0);

      _defineProperty(this, "visibleMediumLarge", false);

      _defineProperty(this, "visibleSmallMediumLarge", false);
    }

    // place complex computed properties here:
    get cellId() {
      return `table-footer-cell-${(0, _genUuid.default)(this)}`;
    }

    get visibleLarge() {
      if (this.visibleMediumLarge || this.visibleSmallMediumLarge) {
        return false;
      }

      return true;
    } // place methods and actions here:


    init() {
      super.init(...arguments);

      if (!this.dataTestId) {
        if (this.name) {
          this.set("dataTestId", this.name);
        } else {
          this.set("dataTestId", this.cellId);
        }
      }
    }

  }, (_applyDecoratedDescriptor(_class2.prototype, "visibleLarge", [_dec6], Object.getOwnPropertyDescriptor(_class2.prototype, "visibleLarge"), _class2.prototype)), _class2)) || _class) || _class) || _class) || _class) || _class);
  _exports.default = EfsTableFooterCell;
});