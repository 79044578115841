define("efs-components/templates/components/efs-accordion-row", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "b1FKMXZh",
    "block": "[[[18,1,[[28,[37,1],null,[[\"header\",\"content\"],[[50,\"efs-accordion-header\",0,null,[[\"isActive\",\"onToggleRow\",\"for\",\"dataTestId\"],[[30,0,[\"active\"]],[28,[37,3],[[30,0],\"toggleRow\"],null],[30,0,[\"dataTestId\"]],[30,0,[\"dataTestId\"]]]]],[50,\"efs-accordion-content\",0,null,[[\"isActive\",\"for\",\"dataTestId\"],[[30,0,[\"active\"]],[30,0,[\"dataTestId\"]],[30,0,[\"dataTestId\"]]]]]]]],[30,0,[\"active\"]],[30,0,[\"dataTestId\"]]]],[1,\"\\n\"]],[\"&default\"],false,[\"yield\",\"hash\",\"component\",\"action\"]]",
    "moduleName": "efs-components/templates/components/efs-accordion-row.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});