define("efs-components/utils/key-codes", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.KEY_CODE_UP = _exports.KEY_CODE_RIGHT = _exports.KEY_CODE_LEFT = _exports.KEY_CODE_ESCAPE = _exports.KEY_CODE_ENTER = _exports.KEY_CODE_DOWN = _exports.KEY_CODE_DELETE = _exports.KEY_CODE_BACKSPACE = _exports.CLOSABLE_KEY_CODES = void 0;
  const KEY_CODE_BACKSPACE = 8;
  _exports.KEY_CODE_BACKSPACE = KEY_CODE_BACKSPACE;
  const KEY_CODE_DELETE = 46;
  _exports.KEY_CODE_DELETE = KEY_CODE_DELETE;
  const KEY_CODE_ENTER = 13;
  _exports.KEY_CODE_ENTER = KEY_CODE_ENTER;
  const KEY_CODE_ESCAPE = 27;
  _exports.KEY_CODE_ESCAPE = KEY_CODE_ESCAPE;
  const KEY_CODE_DOWN = 40;
  _exports.KEY_CODE_DOWN = KEY_CODE_DOWN;
  const KEY_CODE_UP = 38;
  _exports.KEY_CODE_UP = KEY_CODE_UP;
  const KEY_CODE_LEFT = 37;
  _exports.KEY_CODE_LEFT = KEY_CODE_LEFT;
  const KEY_CODE_RIGHT = 39;
  _exports.KEY_CODE_RIGHT = KEY_CODE_RIGHT;
  const CLOSABLE_KEY_CODES = [KEY_CODE_BACKSPACE, KEY_CODE_DELETE, KEY_CODE_ENTER, KEY_CODE_ESCAPE];
  _exports.CLOSABLE_KEY_CODES = CLOSABLE_KEY_CODES;
});