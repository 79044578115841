define("efs-components/helpers/classify", ["exports", "@ember/component/helper", "@ember/object", "@ember/array", "@ember/utils"], function (_exports, _helper, _object, _array, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.classify = classify;
  _exports.default = void 0;
  const DELIMITER_MODIFIER = "--";
  const DELIMITER_ELEMENT = "__";
  const DELIMITER_CLASS = " ";
  const DELIMITER_MODIFIER_STRING = ",";

  function classify(params, hash) {
    let blockName = getBlockName(params, hash);
    let blockModifiers = getBlockModifiers(params, hash);
    let elementName = getElementName(params, hash);
    let elementModifiers = getElementModifiers(params, hash);

    if (!canGenerateClassNames(blockName, blockModifiers, elementName)) {
      return "";
    }

    let blocks = [];
    blocks.push(blockName);
    blocks = blocks.concat(generateModifiedNames(blockName, blockModifiers));
    let elements = [];

    if (elementName) {
      elements.push(elementName);
      elements = elements.concat(generateModifiedNames(elementName, elementModifiers));
    }

    let classNames = blocks.reduce((classNames, block) => {
      if ((0, _utils.isEmpty)(elements)) {
        classNames.push(block);
      } else {
        elements.forEach(element => {
          classNames.push(block + DELIMITER_ELEMENT + element);
        });
      }

      return classNames;
    }, []);
    return classNames.join(DELIMITER_CLASS);
  }

  var _default = (0, _helper.helper)(classify);

  _exports.default = _default;

  function generateModifiedNames(name, modifiers) {
    let mods = [];

    if (typeof modifiers === "string" && !(0, _utils.isEmpty)(modifiers)) {
      mods = mods.concat(modifiers.split(DELIMITER_MODIFIER_STRING));
    } else if ((0, _array.isArray)(modifiers)) {
      mods = mods.concat(modifiers);
    }

    return mods.reduce((names, value) => {
      names.push(name + DELIMITER_MODIFIER + value);
      return names;
    }, []);
  }

  function canGenerateClassNames(blockName, blockModifiers, elementName) {
    let hasBlockName = !(0, _utils.isEmpty)(blockName);
    let hasBlockModifiers = !(0, _utils.isEmpty)(blockModifiers);
    let hasElementName = !(0, _utils.isEmpty)(elementName);
    return hasBlockName || hasBlockName && hasBlockModifiers || hasBlockName && hasElementName;
  }

  function getBlockName(params, hash) {
    let blockName = "";

    if ((0, _array.isArray)(params) && !(0, _utils.isEmpty)(params)) {
      blockName = params[0];
    }

    if (hash && (0, _object.get)(hash, "blockName")) {
      blockName = (0, _object.get)(hash, "blockName");
    }

    return blockName;
  }

  function getBlockModifiers(params, hash) {
    let modifiers = [];

    if ((0, _array.isArray)(params) && params.length > 1) {
      modifiers = params[1];
    }

    if (hash && (0, _object.get)(hash, "blockModifiers")) {
      modifiers = (0, _object.get)(hash, "blockModifiers");
    }

    return modifiers;
  }

  function getElementName(params, hash) {
    let elementName = "";

    if ((0, _array.isArray)(params) && params.length > 2) {
      elementName = params[2];
    }

    if (hash && (0, _object.get)(hash, "elementName")) {
      elementName = (0, _object.get)(hash, "elementName");
    }

    return elementName;
  }

  function getElementModifiers(params, hash) {
    let modifiers = [];

    if ((0, _array.isArray)(params) && params.length > 3) {
      modifiers = params[3];
    }

    if (hash && (0, _object.get)(hash, "elementModifiers")) {
      modifiers = (0, _object.get)(hash, "elementModifiers");
    }

    return modifiers;
  }
});