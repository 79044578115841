define("efs-components/components/efs-link-button", ["exports", "@ember-decorators/component", "@ember/component", "efs-components/utils/gen-uuid", "efs-components/templates/components/efs-link-button"], function (_exports, _component, _component2, _genUuid, _efsLinkButton) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  let EfsLinkButton = (_dec = (0, _component.layout)(_efsLinkButton.default), _dec2 = (0, _component.tagName)("button"), _dec3 = (0, _component.classNames)("link-button"), _dec4 = (0, _component.attributeBindings)("dataTestId:data-test-link-button", "data-tooltip", "data-tooltip-active", "data-tooltip-system", "disabled", "name", "tabindex", "title", "type", "value"), _dec(_class = _dec2(_class = _dec3(_class = _dec4(_class = class EfsLinkButton extends _component2.default {
    constructor() {
      super(...arguments);

      _defineProperty(this, "data-test-link-button", true);

      // place simple properties and attributes in alphabetical order here:
      _defineProperty(this, "dataTestId", null);

      _defineProperty(this, "disabled", false);

      _defineProperty(this, "name", "");

      _defineProperty(this, "tabindex", 0);

      _defineProperty(this, "title", "");

      _defineProperty(this, "type", "button");

      _defineProperty(this, "value", "");
    }

    // place complex computed properties here:
    get buttonId() {
      return `link-button-${(0, _genUuid.default)(this)}`;
    } // place methods and actions here:


    init() {
      super.init(...arguments);

      if (!this.dataTestId) {
        if (this.name) {
          this.set("dataTestId", this.name);
        } else {
          this.set("dataTestId", this.buttonId);
        }
      }
    }

  }) || _class) || _class) || _class) || _class);
  _exports.default = EfsLinkButton;
});